@import "../../SiteShared.scss";
table {
  border-spacing: 0;
  border: 1px solid #ededed;
}
table tr:last-child td {
  border-bottom: 0;
}
table th,
table td {
  text-align: center;
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
  // Button{
  //   opacity:0
  // }
}

table tr[role="row"]:hover {
  Button {
    background: $accent-color;
  }
}
table th:last-child,
table td:last-child {
  border-right: 0;
}
table tr:nth-child(even) {
  background-color: #fafafa;
}
table th {
  background: $primary-color;
  color: $accent-color-light;
}
table th::before {
  position: absolute;
  right: 15px;

  top: 16px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
table th.sort-asc::before {
  border-bottom: 5px solid $accent-color-light;
}
table th.sort-asc,
table th.sort-desc {
  background: #4c158a;
}
table th.sort-desc::before {
  border-top: 5px solid $accent-color-light;
}

.table-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}
.badge {
  background-color: #9ae6b4;
  color: #22543d;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
input {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: none;
}
