@import "../../styles/_fonts";
@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/_variables";
@import "../../styles/_card";
@import "../../styles/_button";
@import "../../styles/_base";

.img-tall-overflow {
  height: 28.125em;
  overflow: hidden;
}

.img-fluid {
  height: 100% !important;
  object-fit: cover !important;
}

.trips-prev {
  transform: translateX(-3.5em);
}

.batch-icon-arrow-left,
.batch-icon-arrow-right {
  color: white !important;
}
.trips-next {
  transform: translateX(3.8em);
}

.offer-img-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5;
}

.offer-title {
  position: absolute;
  color: white;
  font-size: 1.5em;
  left: 3%;
  bottom: 5%;
  transition: transform 2s ease 0s;
}

.offer-img-overlay:hover {
  .offer-title {
    transform: scale(1.1);
  }
}

.offer-price {
  position: absolute;
  color: white;
  right: 15%;
  bottom: 2%;
  z-index: -1;
  transform: translateY(1em);
}

.offer-points {
  text-align: center;
  border-radius: 0.2167em;
  height: 1.5em;
  width: 4em;
  background: #ffffffe1;
  position: absolute;
  right: 11%;
  top: 1%;
  font-size: 1.25em;
}

.batch-icon-star-alt {
  color: $qp-color-yellow;
}

hr {
  display: block;
  border-top: 1px solid #ccc;
  bottom: 5%;
  width: 50%;
  position: absolute;
}

.carousel-small {
  display: none;
}

@media (max-width: 767px) {
  .carousel-full {
    display: none;
  }
  .carousel-small {
    display: block;
    img {
      width: 100%;
      height: auto;
    }
    .trips-prev {
      transform: translateX(0.3em);
    }
    .trips-next {
      transform: translateX(0em);
    }
  }
}
