.page-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .page-header-title{
    font-size: 20px;
    font-weight: 800;
    padding-left: 30px;
    line-height: 1;
    color: #171a1d;
  }
}

.renew-lease-header{
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0 11px 14px -10px #e5eced;
  background-color: #fff;
  margin-bottom: 30px;
}

.resident-profile-icon {
  margin-right: 20px;
  padding: 18px 17px 18px 18px;
  border-radius: 3px;
  background-color: #e3e0e6;
}
.profile-title {
  font-size: 18px;
  font-weight: bold;
  color: #3b1c5a;
}
.profile-subtitle {
  font-size: 13px;
  font-weight: 500;
  color: #5e6267;
  margin-top: 1px;
  display: flex;
  align-items: center;
  a{
    color: #5e6267 !important;
  }
}

.theme-card {
  &.bordered{
    box-shadow: 0 11px 14px -10px #e5eced;
    border: dashed 1px #3b1c5a;
  }
  &.card {
    box-shadow: 0 11px 14px -10px #e5eced !important;
    background-color: #fff;
    margin-bottom: 30px;
    .card-header {
      padding: 17px 20px 16px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      background-color: #f4f4f4;
      border: 0;
      margin: 0;
      .card-header-title {
        font-size: 12px;
        font-weight: bold;
        color: #171a1d;
        text-transform: uppercase;
      }
      .card-profile-header {
        padding: 13px 20px;
      }
      &.unit-header{
        background-color: #eeecf0;
        padding: 24px 20px;
        text-align: center;
        cursor: pointer;
        .card-header-title{
          font-size: 15px;
          font-weight: bold;
          color: #3b1c5a;
        }
      }
    }
    .card-body {
      padding: 20px;
      .card-list {
        .card-list-item {
          padding: 20px 0;
          border-bottom: 1px dashed #0000001a;
          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
          }
          &:first-child {
            padding-top: 0;
          }
          .list-title {
            font-size: 14px;
            font-weight: 600;
            color: #171a1d;
          }
          .list-text {
            font-size: 13px;
            font-weight: 500;
            color: #5e6267;
          }
          .list-text-dark {
            @extend .list-text;
            color: #171a1d;
          }
          .card-text-light {
            font-size: 12px;
            color: #5e6267;
          }
        }
      }
      .card-profile-body {
        padding: 10px 20px;
      }
      .card-row-notice{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px dashed #0000001a;
        padding: 20px 0;
        &:first-child{
          padding-top: 0;
        }
        &:last-child{
          border-bottom: 0;
          padding-bottom: 0;
        }
        .notice-title{
          font-size: 13px;
          font-weight: 500;
          color: #5e6267;
        }
        .notice-subtitle{
          font-size: 13px;
          font-weight: 500;
          color: #171a1d;
        }
        .notice-time{
          font-size: 12px;
          color: #5e6267;
          white-space: nowrap;
          margin-left: 30px;
        }
      }
      .card-row-services{
        margin: 0 -7.5px;
        display: flex;
        flex-wrap: wrap;
        .services-label{
          margin: 0 7.5px 15px;
          padding: 4px 15px 7px;
          // opacity: 0.05;
          border-radius: 15px;
          background-color: #5e62671d;
          font-size: 13px;
          font-weight: 500;
          color: #5e6267;
        }
      }
      .card-row-stats{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -16.5px;
        .stats-col{
          margin: 0 16.5px 10px;
          flex: 1 1 auto;
          .stats-label{
            font-size: 13px;
            margin-bottom: 5px;
            color: #5e6267;
          }
          .stats-value{
            font-size: 15px;
            color: #171a1d;
          }
        }
      }
      .maintenance-card{
        padding: 20px 20px 25px;
        border-radius: 3px;
        background-color: #5e63680d;
        margin-bottom: 15px;
        .maintenance-icon{
          min-width: 35px;
          width: 35px;
          height: 35px;
          margin: 5px 15px 12px 0;
          padding: 5px;
          border-radius: 3px;
          background-color: #b9babb33;
        }
        .maintenance-label{
          font-size: 16px;
          font-weight: 500;
          color: #171a1d;
        }
        .maintenance-time{
          font-size: 13px;
          font-weight: 500;
          color: #5e6267;
        }
        .maintenance-description{
          line-height: 1.86;
          color: #5e6267;
        }
        .points-card{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .maintenance-label{
            font-size: 14px;
          }
          .maintenance-time{
            font-weight: 400;
          }
          .points-label{
            font-size: 15px;
            font-weight: bold;
            display: flex;
            margin-left: 30px;
            &.success{
              color: #11ae7d;
            }
          }
        }
      }
      .points-header{
        padding: 7px 20px;
        background-color: #5e62670d;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .points-head-label{
          font-size: 11px;
          font-weight: bold;
          color: #5e6267;
          text-transform: uppercase;
          min-width: 60px;
        }
      }
      .payment-table-header{
        display: flex;
        padding: 12px 0;
        background-color: #f8f8f8;
        margin-bottom: 10px;
        .table-header-label{
          flex: 1 1 auto;
          font-size: 11px;
          font-weight: 500;
          color: #5e6267;
          padding: 0 20px;
          text-transform: uppercase;
          &:nth-child(1){
            width: 30%;
          }
          &:nth-child(2){
            width: 120px;
          }
          &:nth-child(3){
            width: 100px;
          }
          &:nth-child(4){
            width: 100px;
          }
          &:nth-child(5){
            width: 80px;
          }
        }
      }
      .payment-table-body{
        .payment-table-row{
          padding: 30px 0;
          background-color: #5e62670d;
          margin-bottom: 20px;
          display: flex;
          .table-col{
            flex: 1 1 auto;
            color: #5e6267;
            padding: 0 20px;
            .status{
              border-radius: 15px;
              background-color: #5e62670d;
              font-size: 11px;
              font-weight: bold;
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: uppercase;
              height: 25px;
              width: 80px;
              &.success{
                background-color: #11ae7d0d;
                color: #11ae7d;
              }
            }
            .payment-property-title{
              font-weight: 500;
              color: #171a1d;
            }
            .payment-property-subtitle{
              font-size: 13px;
            }
            &:nth-child(1){
              width: 30%;
            }
            &:nth-child(2){
              width: 120px;
            }
            &:nth-child(3){
              width: 100px;
            }
            &:nth-child(4){
              width: 100px;
            }
            &:nth-child(5){
              width: 80px;
            }
          }
        }
      }
    }
  }
}

.profile-basic {
  margin-bottom: 23px;
}
.profile-label {
  font-size: 14px;
  color: #5e6267;
  margin-bottom: 5px;
}
.profile-label-value {
  font-size: 14px;
  line-height: 1.79;
  color: #181b20;
}
.profile-label-row {
  display: flex;
  align-items: center;
  margin: 9px 0 30px;
}
.profile-label-title {
  font-size: 16px;
  font-weight: bold;
  color: #181b20;
}
.profile-label-subtitle {
  font-size: 13px;
  color: #5e6267;
  margin-left: 5px;
}
.profile-label-line {
  height: 1px;
  border-bottom: 1px dashed #0000001a;
  flex: 1 1 auto;
  margin-left: 20px;
  margin-top: 4px;
}

.dot-separator{
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 10px 10px 5px;
  opacity: 0.3;
  background-color: #5e6267;
}

.docs-title{
  font-size: 15px;
  font-weight: bold;
  margin: 20px 0;
  text-transform: uppercase;
}
.uploaded-docs-row{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  .uploaded-docs-col{
    width: 100px;
    min-width: 100px;
    height: 100px;
    border-radius: 3px;
    margin: 0 5px 10px;
    cursor: pointer;
    a{
      cursor: pointer;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.next-btn {
  width: auto;
  padding: 3px 20px;
  height: 45px;
  padding: 0;
  border-radius: 3px;
  background-image: linear-gradient(103deg, #3b1c5a, #374db1 162%);
  font-family: "Be Vietnam", sans-serif !important;
  font-size: 13px;
  font-weight: 800;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0;
  line-height: 30px;
}

