.renter-banner {
  .slick-slider {
    height: 400px;
    .slick-list,
    .slick-track {
      height: 100%;
      border-radius: 3px;
    }
  }
  .slick-slide {
    position: relative;
    div,
    img {
      height: 100%;
    }
    .carousel-caption {
      width: 100%;
      text-align: left;
      bottom: auto;
      top: 50%;
      left: 0;
      transform: translate(0px, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px 50px;
      h5 {
        font-size: 25px !important;
        font-weight: 800 !important;
        font-family: "Be Vietnam", sans-serif !important;
        color: #ffffff !important;
        @media (max-width: 768px) {
          font-size: 25px;
          line-height: 1.4;
          margin-bottom: 10px;
        }
      }
      p {
        opacity: 0.8;
        font-size: 14px;
        line-height: 2;
        margin: 0px 0px 20px;
        font-weight: 500;
        color: #ffffff !important;
        max-width: 372px;
      }
    }
  }
  .slick-dots {
    background-color: rgba(23, 26, 29, 0.9);
    width: 70px;
    height: 16px;
    bottom: 15px;
    left: 50%;
    border-radius: 10px;
    li {
      width: 6px;
      height: 6px;
      margin: 0 2px;
      padding: 0;
      button {
        width: 100%;
        height: 100%;
        position: absolute;
        top: -3px;
        &:before {
          font-size: 6px;
          line-height: 6px;
          width: 6px;
          height: 6px;
          opacity: 0.3;
          color: #ffffff;
        }
      }
      &.slick-active {
        button {
          &:before {
            opacity: 1;
            color: #ffffff;
          }
        }
      }
    }
  }
  .renter-form {
    .fields {
      margin: 0px;
      .field {
        padding: 0px;
        input {
          padding: 0px 30px;
          color: #171a1d;
          font-size: 14px;
          font-weight: 500;
          margin: 0px;
          border: 0px;
          height: 50px;
          width: 320px;
          border-radius: 3px;
          padding: 0px 20px;
          &::placeholder {
            color: #5e6267;
          }
        }
      }
    }
    .renter-search {
      border-radius: 0 3px 3px 0;
      width: 80px;
      height: 50px;
      border: 0;
      background-image: linear-gradient(129deg, #3b1c5a, #374db1 158%);
      margin-left: -7px;
      padding: 0px;
      cursor: pointer;
      img {
        margin: 0px auto;
        width: 25px;
      }
    }
  }

  .container {
    position: relative;
  }
}
.half-image-section {
  border-radius: 3px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  margin: 20px 0px !important;
  .column {
    box-shadow: unset !important;
    margin: 0px;
    &.row {
      padding: 50px 34px !important;
    }
  }
  h3 {
    font-size: 25px;
    font-weight: 800 !important;
    line-height: 1.5;
    color: #3b1c5a;
    margin-bottom: 10px;
    font-family: "Be Vietnam", sans-serif !important;
  }
  .text {
    line-height: 2.12;
    color: #5e6267;
    margin-bottom: 20px;
    padding-right: 40px;
  }
}
.homebtn {
  border-radius: 3px;
  background-image: linear-gradient(102deg, #3b1c5a, #374db1 163%);
  height: 45px;
  color: #ffffff !important;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 800;
}
.renter-image-section {
  h3 {
    margin-top: 40px;
  }
  .main-image {
    padding-right: 100px;
    padding-left: 0px;
    width: 100%;
  }

  .fixed-left-image {
    right: 55px !important;
    width: 300px;
    position: absolute;
    bottom: 40px;
  }
  .homebtn {
    width: 200px;
  }
}
.renter-point-section {
  h3 {
    margin-top: 20px;
  }
  .main-image {
    padding-left: 100px;
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .fixed-top-image {
    width: 200px;
    height: 220px;
    object-fit: contain;
    position: absolute;
    bottom: 30px;
    left: 70px;
  }
  .homebtn {
    width: 250px;
  }
}
.community-section {
  .image-container {
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
  }
  h3 {
    text-align: center;
    margin-top: 30px;
  }
  p {
    text-align: center;
    margin-top: 0px;
    line-height: 32px;
    color: #5e6267;
  }
  .homebtn {
    width: 200px;
    margin: 0px auto;
  }
}
.input-with-button {
  .row {
    @media (max-width: 768px) {
      display: block !important;
    }
  }
}

.contact-hover:hover {
  color: purple;
}
.contact-hover {
  color: blue;
}

.card-header {
  padding: 0px;
  margin: 0px;
  border: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    .right-head {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      width: 100%;
      .button {
        width: 100% !important;
      }
    }
  }
  .contact-us-menu-explore {
    height: auto !important;
    width: 300px !important;
    z-index: 500 !important;
    position: absolute !important;
    left: -170px !important;
    span {
      text-transform: capitalize !important;
    }
  }
  h5 {
    font-size: 16px !important;
    font-weight: 500 !important;
    margin-bottom: 0px;
    color: #171a1d;
    text-transform: capitalize;
  }
  h6 {
    font-size: 13px;
    font-weight: 500 !important;
    color: #5e6267;
    text-transform: capitalize;
    margin-bottom: 0px;
  }
  .action-btn {
    width: 130px;
    height: 25px;
    border-radius: 15px;
    font-size: 11px;
    font-weight: bold;
    color: #fff;
    display: inline-block;
    background-color: #11ae7d;
    text-align: center;
    line-height: 25px;
    &.resolved-btn {
      background-color: #171a1d;
    }
    &.cancelled-btn {
      background-color: #ef2c2c;
    }
  }
  .ellipsis {
    border-radius: 3px;
    background-color: rgba(59, 28, 90, 0.05);
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-left: 5px;
    line-height: 25px;
    cursor: pointer;
    font-size: 12px;
    color: #5e6267;
    font-weight: unset;
  }
  .menu {
    width: 210px;
    height: 145px;
    padding: 20px 0;
    border-radius: 3px;
    box-shadow: 0 11px 14px -10px #e5eced;
    background-color: #ffffff;
    right: 4px;
    left: unset;
    border: 0px;
    top: 27px;
    .text {
      font-size: 13px;
      font-weight: normal;
      color: #5e6267;
    }
  }
}

div.centered.one.column.row.rental {
  padding-top: 0 !important;
  padding-bottom: 20px !important;
}

div.one.column.row.explore {
  padding-bottom: 16px !important;
}

.explore-banner {
  position: relative;
  .banner-link {
    max-width: 340px;
  }
}
