@import "../../../styles/_variables";

.archive-chat-request-modal {
  .content {
    padding: 0 !important;
  }
}

.chat-modal-content {
  padding: 50px;

  .title {
    font-family: "BeVietnam", sans-serif;
    font-size: 22px;
    font-weight: 700;
    color: #171a1d;
  }

  .description {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: $gray-dark;
  }

  .text-input {
    margin-top: 30px;
    height: 75px;
    background-color: #fff;
    padding: 15px 20px;

    label {
      color: $gray-dark;
      font-size: 13px;
      font-weight: 500;
      margin: 0;
    }

    input {
      border: 0 !important;
      box-shadow: none !important;
      font-size: 14px;
      font-weight: 500;
      padding: 0;
      color: #171a1d;
    }
  }

  .list {
    height: 150px;
    margin: 15px 0;
    overflow-y: auto;

    .contact-item {
      cursor: pointer;
      display: flex;
      margin: 5px 0;
      padding: 5px;
      border-radius: 3px;
      background-color: #fff;
      line-height: 35px;
      font-size: 13px;
      font-weight: 400;
      font-family: "BeVietnam", sans-serif;
      color: #000;

      &.active {
        background-color: rgba(
          red($primary-color),
          green($primary-color),
          blue($primary-color),
          0.1
        );
      }

      img {
        width: 35px;
        height: 35px;
        border-radius: 50px;
        margin-right: 15px;
      }

      .name {
        flex: 1;
      }

      span {
        align-self: flex-end;
      }
    }
  }

  .btn {
    margin-top: 30px;
    width: 100%;
  }
}
