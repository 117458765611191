@import "./_variables";
@import "./quillpro/quillpro-variables";

/*font classes*/
.font-font {
  font-family: $light-font !important;
}
.font-regular {
  font-family: $regular-font !important;
}
.font-bold {
  font-family: $bold-font !important;
}
.font-black {
  font-family: $black-font !important;
}
.h-100 {
  height: 100% !important;
}
.heading {
  &.heading-md {
    font-size: 23px;
    font-family: $black-font;
  }
}
.star-count {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid $gray-fade;
  color: $qp-color-warning;
  font-style: normal;
  &:before {
    content: "";
    width: 20px;
    height: 20px;
    background: url("/assets/icons/star.svg") no-repeat center;
    display: inline-block;
    background-size: 15px;
    margin-right: 8px;
    position: relative;
    top: 3px;
  }
}
.action-arrow {
  height: 50px;
  width: 50px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: url("/assets/icons/right-arrow-stroke.svg") no-repeat center
    $primary-color;
  border-bottom-right-radius: 0.4167rem;
  background-size: 20px;
}
.heart-btn {
  position: absolute;
  height: 35px;
  width: 35px;
  top: 15px;
  right: 15px;
  background: url("/assets/icons/heart.svg") no-repeat center $white;
  background-size: 20px;
  border-radius: 50%;
}
.active-btn {
  position: absolute;
  height: 35px;
  min-width: 90px;
  top: 15px;
  right: 15px;
  background: $white;
  color: $primary-color;
  border-radius: 4px;
  text-align: center;
  line-height: 35px;
}
/*navbar search*/
.navbar.navbar-light {
  form {
    &.header-search {
      .form-control {
        height: 40px;
        width: 200px;
        border: 0;
        box-shadow: none;
        padding: 0;
        font-size: 14px;
        color: $black !important;
        &:focus {
          color: $black !important;
        }
      }
      .input-group {
        .input-group-append {
          button {
            width: 20px;
            background: none;
            color: $black;
            border: 0;
            height: 40px;
            padding: 0;
            margin-right: 15px;
          }
        }
      }
    }
  }
}
.navbar-notifications {
  li {
    &.nav-item {
      padding: 0 15px 0 20px;
      border-right: 1px solid $qp-color-grey;
    }
    .notification-number {
      width: 20px;
      height: 20px;
      display: inline-block;
      border-radius: 50%;
      background: $primary-color;
      text-align: center;
      line-height: 20px;
      font-size: 10px;
      color: $white;
      position: relative;
      top: -15px;
    }
  }
}

// .navbar {
//   .navbar-nav{
//     &.navbar-profile {
//       .nav-item {
//         .nav-link{
//           &.dropdown-toggle {
//             &::after {
//               margin-top: 20px !important;
//             }
//           }
//         }
//         &:focus, &:hover {
//           a {
//             color: inherit !important;
//           }
//         }
//       }
//     }
//   }
// }
