/* QUILLPRO CORE STYLES */
@import "./styles/_fonts";
@import "./styles/quillpro/quillpro-variables";
@import "./styles/_variables";
@import "./styles/_card";
@import "./styles/_button";
@import "./styles/_base";
@import "./styles/_responsive-table";
@import "./styles/utilites";
@import "./SiteShared.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam:wght@400;500;600;700&display=swap");
/* General Styles */

/* General Styles */
#freshworks-container iframe {
  bottom:0px !important;
  // width:100px !important;
  top:unset !important;
  left:unset !important;
}

body {
  max-height: 100vh !important;
  // min-width:768px !important;
  background-color: #f6f7f8;
  color: $theme-text-color;
  line-height: $qp-golden-ratio;
  letter-spacing: 0.03em;
  font-family: "Be Vietnam", sans-serif !important;
  // @media only screen and (min-width: 386px) and (max-width: 767px) {
  //   max-width: 480px !important;
  //   width: 480px !important;
  // }
  // @media only screen and (min-width: 200px) and (max-width: 385px)  {
  //   max-width: 385px !important;
  //   width: 385px !important;
  // }
  // @media only screen and (device-width: 768px) {
  //   max-width:768px !important;
  //   width: 768px!important;
  // }
}

//Main app content container (below the top navbar and to the right of the left nav)
.app-content {
  margin-top: 104px;
  padding: 50px;
  border-radius: 0px 0px 6px 6px;
  height: calc(100% - 100px);

  @media (max-width: 768px) {
    padding: 0px;
  }
  // height:100%;
  // bottom: 100px;
  // top: 0px;
  @include not-phone {
    position: relative;
    right: 20px;
    margin-left: $leftnav-width-expanded + 20px;
    width: calc(100% - #{$leftnav-width-expanded + 20px});
    // transition: width 0.5s ease-in-out 0.2s;
    // transition: margin 0.5s;

    &.expanded {
      margin-left: $leftnav-width-collapsed;
      width: calc(100% - #{$leftnav-width-collapsed});
      // transition: width 0.5s;
      // transition: margin 0.5s;
    }
  }

  @include mobile-tablet {
    width: 100% !important;
    margin-top: calc(#{$topnav-height} + 10px);
    margin-left: 0 !important;
  }
}

//footer
.footer {
  // height: $footer-height;
  color: #ffffff;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: calc(100% - 270px);
  left: 270px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 8px;
  a {
    color: #ffffff !important;
    &:hover,
    &:focus,
    &:active {
      color: #ffffff;
    }
  }
  .right-links {
    .list {
      display: flex;
      .item {
        margin-left: 60px;
        a {
          font-size: 14px;
          font-weight: normal;
          color: #fff;
        }
      }
    }
  }
  @include mobile-tablet {
    position: static;
    width: 100% !important;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    flex-direction: column;
    padding: 20px;
    height: auto;
    .left-side {
      font-size: 14px;
      color: #171a1d;
      a {
        color: #171a1d !important;
      }
    }
    .right-links {
      margin: 10px 0 0 !important;
      width: 100%;
      .list {
        justify-content: space-between;
        .item {
          margin: 0 8px;
          a {
            color: #171a1d !important;
            font-weight: lighter;
          }
        }
      }
    }
  }
}

.leftnav {
  &.collapsed {
    & + .right-column .footer {
      width: calc(100% - 55px);
      left: 55px;
    }

    .item {
      padding: 0px 15px !important;
    }
  }
}

a:not(.btn) {
  color: $qp-color-primary;
  @include qp-transition(
    color 0.225s ease-in-out,
    background-color 0.225s ease-in-out
  );

  .batch-icon {
    color: inherit;
    line-height: 1.1;
  }
}

a:not(.btn):focus,
a:not(.btn):hover {
  color: $qp-color-secondary;

  .batch-icon {
    color: $qp-color-secondary;
  }
}

b,
strong {
  font-weight: 500;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400 !important;
  color: $theme-text-color;
  line-height: $qp-golden-ratio;
  font-family: "Be Vietnam", sans-serif !important;
}

.h5,
h5 {
  font-size: 1.2rem !important;
}

.boldness-medium,
.heading {
  font-weight: 500 !important;
}

.boldness-light {
  font-weight: 300 !important;
}

.fw-sbold{
  font-weight: 600;
}

p {
  color: $qp-color-text;
  font-weight: 400;
}

.lead-2 {
  font-size: 1.7rem;
}

.no-shadow,
.no-shadow:focus,
.no-shadow:hover {
  box-shadow: none !important;
}
.invisible,
.invisible-children > *,
.invisible-children-with-scrollbar > *:not([class^="mCustomScrollBox"]),
.invisible-children-with-scrollbar > .mCustomScrollBox > .mCSB_container > * {
  opacity: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: $regular-font;
}

table,
table.table {
  th {
    color: $qp-color-text;
    font-weight: 700;

    a {
      font-weight: 700;
    }
  }

  td {
    font-weight: 300;

    a {
      color: $qp-color-primary;
      font-weight: 400;
    }

    a:focus,
    a:hover {
      color: $qp-color-secondary;
    }
  }

  td,
  th {
    font-size: 1rem;
    border-top: 1px solid darken($qp-hover-highlight, 3%);
  }
}

table.table-hover {
  tbody {
    tr:hover {
      background-color: $qp-hover-highlight;
    }
  }
}

table.align-baseline td,
table.align-baseline th {
  vertical-align: baseline !important;
}

table.align-top td,
table.align-top th {
  vertical-align: top !important;
}

table.align-middle td,
table.align-middle th {
  vertical-align: middle !important;
}

table.align-bottom td,
table.align-bottom th {
  vertical-align: bottom !important;
}

table.align-text-top td,
table.align-text-top th {
  vertical-align: text-top !important;
}

table.align-text-bottom td,
table.align-text-bottom th {
  vertical-align: text-bottom !important;
}

table.thead-light th {
  color: $qp-color-text;
}

table.table-dark {
  background-color: $qp-color-blue !important;

  thead {
    th {
      color: $qp-color-white;
    }
  }

  td,
  th,
  thead th {
    border-color: $qp-color-white;
  }

  tbody {
    th {
      color: $qp-color-white;
      font-weight: 700;
    }
  }
}
// .nav-pills .nav-link.active,
// .show > .nav-pills .nav-link{
// background-color: $qp-color-primary;
// }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: $secondary-color;
  color: $primary-color;

  i {
    color: $white;
  }
}

.tab-content > .tab-pane {
  padding: $qp-default-padding;
}

.dropdown-menu {
  position: absolute !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 0;
  width: 20rem;
  text-transform: uppercase;
  box-shadow: 0 7px 23px 0 rgba(0, 0, 0, 0.1), 0 13px 49px 0 rgba(0, 0, 0, 0.06) !important;
  top: 120%;
  @include qp-transition(top 0.3s ease-in-out !important);
}

.dropdown-menu.show {
  top: 100%;
}

.dropdown-menu-sm {
  width: 20rem;
}

.dropdown-menu-md {
  width: 25rem;
}

.dropdown-menu-lg {
  width: 45rem;
}

.dropdown-menu-fullscreen {
  width: 100%;
  box-shadow: 0 7px 23px 0 rgba(0, 0, 0, 0.1), 0 13px 49px 0 rgba(0, 0, 0, 0.06) !important;
  @include qp-transition(width 0.225s ease-in-out);
}

.dropdown-no-close {
  border-radius: 0.4167rem !important;
}

.dropdown-no-close::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-header {
  color: $qp-color-secondary !important;
}

.dropdown-menu a,
.navbar .dropdown-menu a {
  margin: 0 !important;
  padding: 10px 1.5rem !important;
  color: $qp-color-secondary-gradient-2 !important;
  @include qp-transition(
    color 0.225s ease-in-out,
    background-color 0.225s ease-in-out
  );
}

.dropdown-menu a:focus,
.dropdown-menu a:hover,
.navbar .dropdown-menu a:focus,
.navbar .dropdown-menu a:hover {
  color: $qp-color-secondary !important;
  background-color: $qp-color-primary-gradient-1 !important;
}

.dropdown-item.active,
.dropdown-item.show,
.dropdown-item:active,
.dropdown-menu a.dropdown-item.show,
.navbar .dropdown-item.active,
.navbar .dropdown-item:active,
.navbar .dropdown-menu a.dropdown-item.show {
  color: $qp-color-white !important;
  background-color: $qp-color-primary !important;
}

.dropdown-item {
  white-space: normal;
}

.dropdown-submenu {
  position: relative;

  a::after {
    transform: rotate(-90deg);
    position: absolute;
    right: 1.5rem;
    top: 1.48rem;
  }

  .dropdown-menu {
    top: 70%;
    left: 10%;
  }
}

.batch-icon {
  color: $qp-color-text;
  font-style: normal;
  margin-right: 3px;
}

.batch-icon-sm {
  font-size: 1rem !important;
}

.batch-icon-md {
  font-size: 1.3rem !important;
}

.batch-icon-lg {
  font-size: 2.6rem !important;
}

.batch-icon-xl {
  font-size: 3.3rem !important;
}

.batch-icon-xxl {
  font-size: 4.8rem !important;
}
/* Badges */
.badge,
.label {
  box-shadow: none;
  padding: 0.35rem 0.5rem;
}

.badge-default,
.badge-secondary,
.label-default,
.label-secondary {
  background-color: $qp-color-secondary-light-1;
}

.badge-primary,
.label-primary {
  background-color: $qp-color-primary;
}

.badge-success,
.label-success {
  background-color: $qp-color-success;
}

.badge-info,
.label-info {
  background-color: lighten($qp-color-info, 10%);
}

.badge-warning,
.label-warning {
  color: $qp-color-secondary-dark-1 !important;
  background-color: $qp-color-warning;
}

.badge-danger,
.label-danger {
  background-color: $qp-color-danger;
}

.badge-light,
.label-light {
  color: $qp-color-secondary-dark-1 !important;
}

a.badge-danger,
a.badge-dark,
a.badge-default,
a.badge-info,
a.badge-primary,
a.badge-secondary,
a.badge-success,
a.label-danger,
a.label-dark,
a.label-default,
a.label-info,
a.label-primary,
a.label-secondary,
a.label-success {
  color: $qp-color-white !important;
}

a.badge-light,
a.badge-warning,
a.label-light,
a.label-warning {
  color: $qp-color-secondary-dark-1 !important;
}
/* Backgrounds */
.bg-primary {
  color: $qp-color-white;
  background-color: $qp-color-primary !important;
}

.bg-secondary {
  color: $qp-color-white;
  background-color: $qp-color-secondary !important;
}

.bg-success {
  color: $qp-color-white;
  background-color: $qp-color-success !important;
}

.bg-info {
  color: $qp-color-white;
  background-color: $qp-color-info !important;
}

.bg-warning {
  color: $qp-color-warning-dark-2 !important;
  background-color: $qp-color-warning !important;
}

.bg-danger {
  color: $qp-color-white;
  background-color: $qp-color-danger !important;
}

.bg-dark {
  background-color: $qp-color-darkblue !important;
}

.bg-light {
  background-color: $qp-hover-highlight !important;
}

.bg-tranparent {
  background-color: transparent !important;
}

.bg-danger *,
.bg-danger .batch-icon,
.bg-dark *,
.bg-dark .batch-icon,
.bg-info *,
.bg-info .batch-icon,
.bg-primary *,
.bg-primary .batch-icon,
.bg-secondary *,
.bg-secondary .batch-icon,
.bg-success *,
.bg-success .batch-icon,
.bg-warning *,
.bg-warning .batch-icon {
  color: $qp-color-white;
}

.bg-gradient [class^="card-"],
.bg-gradient [class^="card-"] * {
  color: $qp-color-white !important;
}
/* Highlight Colors - Bottom Border */
.highlight-color-blue {
  color: $qp-color-white !important;
  background-color: $qp-color-primary !important;
}

.highlight-color-green {
  color: $qp-color-white !important;
  background-color: $qp-color-success !important;
}

.highlight-color-orange {
  color: $qp-color-white !important;
  background-color: $qp-color-orange !important;
}

.highlight-color-yellow {
  color: $qp-color-white !important;
  background-color: $qp-color-yellow !important;
}

.highlight-color-red {
  color: $qp-color-white !important;
  background-color: $qp-color-red !important;
}

.highlight-color-purple {
  color: $qp-color-white !important;
  background-color: $qp-color-purple !important;
}

.bg-gradient {
  color: $qp-color-white !important;
  @include qp-gradient(
    $qp-color-primary-gradient-1,
    $qp-color-primary-gradient-2
  );
}

.bg-gradient [class^="card-"] * {
  color: $qp-color-white !important;
}

.bg-secondary.bg-gradient {
  color: $qp-color-white !important;
  @include qp-gradient(
    $qp-color-secondary-gradient-1,
    $qp-color-secondary-gradient-2
  );
  @include qp-transition(width 0.3s ease-in-out);
}

.bg-success.bg-gradient {
  color: $qp-color-white !important;
  @include qp-gradient($qp-color-green-gradient-1, $qp-color-green-gradient-2);
  @include qp-transition(width 0.3s ease-in-out);
}

.bg-info.bg-gradient {
  color: $qp-color-white !important;
  @include qp-gradient($qp-color-blue-gradient-1, $qp-color-blue-gradient-2);
  @include qp-transition(width 0.3s ease-in-out);
}

.bg-warning.bg-gradient {
  color: $qp-color-secondary !important;
  @include qp-gradient(
    $qp-color-orange-gradient-1,
    $qp-color-orange-gradient-2
  );
  @include qp-transition(width 0.3s ease-in-out);
}

.bg-danger.bg-gradient {
  color: $qp-color-white !important;
  @include qp-gradient($qp-color-red-gradient-1, $qp-color-red-gradient-2);
  @include qp-transition(width 0.3s ease-in-out);
}

.bg-danger p,
.bg-dark p,
.bg-info p,
.bg-primary p,
.bg-secondary p,
.bg-success p {
  color: $qp-color-white;
}

.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-secondary h1,
.bg-secondary h2,
.bg-secondary h3,
.bg-secondary h4,
.bg-secondary h5,
.bg-secondary h6 {
  color: $qp-color-white;
}

.bg-warning p {
  color: $qp-color-warning-dark-2 !important;
}
/* Borders */
.border-priamry {
  border-color: $qp-color-primary !important;
}

.border-secondary {
  border-color: $qp-color-secondary !important;
}

.border-success {
  border-color: $qp-color-success !important;
}

.border-danger {
  border-color: $qp-color-danger !important;
}

.border-warning {
  border-color: $qp-color-warning !important;
}

.border-info {
  border-color: $qp-color-info !important;
}
/* Text Colors */
.text-priamry {
  color: $qp-color-primary !important;

  .batch-icon {
    color: $qp-color-primary !important;
  }
}

.text-secondary {
  color: $qp-color-secondary !important;

  .batch-icon {
    color: $qp-color-secondary !important;
  }
}

.text-success {
  color: $qp-color-success !important;

  .batch-icon {
    color: $qp-color-success !important;
  }
}

.text-danger {
  color: $qp-color-danger !important;

  .batch-icon {
    color: $qp-color-danger !important;
  }
}

.text-warning {
  color: $qp-color-warning !important;

  .batch-icon {
    color: $qp-color-warning !important;
  }
}

.text-info {
  color: $qp-color-info !important;

  .batch-icon {
    color: $qp-color-info !important;
  }
}
/* Buttons */
.btn {
  margin: 6px 3px;
  // @include qp-button-size-md($qp-active-font);
  // @include qp-button-padding($qp-active-font);
  @include qp-transition(all 0.225s ease-in-out);
  box-shadow: none !important;
}

.btn.disabled,
.btn:disabled {
  // text-decoration: line-through;
  cursor: not-allowed;
}

.btn-group-lg > .btn,
.btn.btn-lg {
  @include qp-button-size-lg($qp-active-font);

  .batch-icon {
    font-size: 1.25rem;
  }
}

.btn-group-sm > .btn,
.btn.btn-sm {
  padding: 0.5rem 1.6rem;
  @include qp-button-size-sm($qp-active-font);

  .batch-icon {
    font-size: 0.8rem;
  }
}

.btn-link,
.btn-link.dropdown-toggle {
  color: $qp-color-secondary !important;

  .batch-icon {
    color: $qp-color-secondary !important;
  }
}

.btn-link.dropdown-toggle:focus,
.btn-link.dropdown-toggle:hover,
.btn-link.focus,
.btn-link:focus,
.btn-link:hover {
  color: $qp-color-secondary !important;
  background-color: #eaeaea !important;
}

.btn-link.disabled,
.btn-link:disabled {
  color: $qp-color-secondary !important;
}

.btn-link.active,
.btn-link:active,
.show > .btn-primary.dropdown-toggle {
  color: $qp-color-secondary !important;
}

.btn-link:active:focus,
.btn-link:active:hover {
  color: $qp-color-secondary !important;
}

.btn-primary,
.btn-primary.dropdown-toggle {
  background-color: $qp-color-primary !important;
}

.btn-primary.dropdown-toggle:focus,
.btn-primary.dropdown-toggle:hover,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
  background-color: $qp-color-primary-light-1 !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: $qp-color-primary-light-2 !important;
}

.btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: $qp-color-primary-dark-1 !important;
}

.btn-primary:active:focus,
.btn-primary:active:hover {
  background-color: $qp-color-primary-dark-1 !important;
}

.btn-secondary,
.btn-secondary.dropdown-toggle {
  background-color: $qp-color-secondary !important;
}

.btn-secondary.dropdown-toggle:focus,
.btn-secondary.dropdown-toggle:hover,
.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:hover {
  background-color: $qp-color-secondary-light-1 !important;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: $qp-color-secondary-light-2 !important;
}

.btn-secondary:not([disabled]):not(.disabled).active,
.btn-secondary:not([disabled]):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-color: $qp-color-secondary-dark-1 !important;
}

.btn-secondary:active:focus,
.btn-secondary:active:hover {
  background-color: $qp-color-secondary-light-3 !important;
}

.btn-success,
.btn-success.dropdown-toggle {
  background-color: $qp-color-success !important;
}

.btn-success.dropdown-toggle:focus,
.btn-success.dropdown-toggle:hover,
.btn-success.focus,
.btn-success:focus,
.btn-success:hover {
  background-color: $qp-color-success-light-1 !important;
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: $qp-color-success-light-2 !important;
}

.btn-success:not([disabled]):not(.disabled).active,
.btn-success:not([disabled]):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background-color: $qp-color-success-dark-1 !important;
}

.btn-success:active:focus,
.btn-success:active:hover {
  // Let this take the default Bootstrap or MDB style // background-color: $qp-color-success-light-3 !important;
}

.btn-info,
.btn-info.dropdown-toggle {
  background-color: $qp-color-info !important;
}

.btn-info.dropdown-toggle:focus,
.btn-info.dropdown-toggle:hover,
.btn-info.focus,
.btn-info:focus,
.btn-info:hover {
  background-color: $qp-color-info-light-2 !important;
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: $qp-color-info-light-2 !important;
}

.btn-info:not([disabled]):not(.disabled).active,
.btn-info:not([disabled]):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  background-color: $qp-color-info-dark-1 !important;
}

.btn-info:active:hover {
  background-color: $qp-color-info-dark-2 !important;
}

.btn-warning,
.btn-warning.dropdown-toggle {
  color: $qp-color-warning-dark-2 !important;
  background-color: $qp-color-warning !important;
}

.btn-warning.dropdown-toggle:focus,
.btn-warning.dropdown-toggle:hover,
.btn-warning.focus,
.btn-warning:focus,
.btn-warning:hover {
  background-color: $qp-color-warning-light-1 !important;
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: $qp-color-warning-light-2 !important;
}

.btn-warning:not([disabled]):not(.disabled).active,
.btn-warning:not([disabled]):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  background-color: $qp-color-warning-dark-1 !important;
}

.btn-warning:active:focus,
.btn-warning:active:hover {
  // Let this take the default Bootstrap or MDB style // background-color: $qp-color-warning-dark-1 !important;
}

.btn-danger,
.btn-danger.dropdown-toggle {
  background-color: $qp-color-danger !important;
}

.btn-danger.dropdown-toggle:focus,
.btn-danger.dropdown-toggle:hover,
.btn-danger.focus,
.btn-danger:focus,
.btn-danger:hover {
  background-color: $qp-color-danger-light-1 !important;
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: $qp-color-danger-light-2 !important;
}

.btn-danger:not([disabled]):not(.disabled).active,
.btn-danger:not([disabled]):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  background-color: $qp-color-danger-dark-1 !important;
}

.btn-danger:active:focus,
.btn-danger:active:hover {
  // Let this take the default Bootstrap or MDB style
  background-color: $qp-color-danger-light-3 !important;
}

.btn-outline-primary {
  border-color: $qp-color-primary !important;
  color: $qp-color-primary-dark-1 !important;

  .batch-icon {
    color: $qp-color-primary-dark-1 !important;
  }
}

.btn-outline-primary:focus,
.btn-outline-primary:hover {
  border-color: $qp-color-primary !important;
  color: $qp-color-primary !important;
}

.btn-outline-primary:not([disabled]):not(.disabled).active,
.btn-outline-primary:not([disabled]):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: $qp-color-primary !important;
  color: $qp-color-white !important;
}

.btn-outline-secondary {
  border-color: $qp-color-secondary !important;
  color: $qp-color-secondary-dark-1 !important;

  .batch-icon {
    color: $qp-color-secondary-dark-1 !important;
  }
}

.btn-outline-secondary:focus,
.btn-outline-secondary:hover {
  border-color: $qp-color-secondary !important;
  color: $qp-color-secondary !important;
}

.btn-outline-secondary:not([disabled]):not(.disabled).active,
.btn-outline-secondary:not([disabled]):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  background-color: $qp-color-secondary !important;
  color: $qp-color-white !important;
}

.btn-outline-success {
  border-color: $qp-color-success !important;
  color: $qp-color-success-dark-1 !important;

  .batch-icon {
    color: $qp-color-success-dark-1 !important;
  }
}

.btn-outline-success:focus,
.btn-outline-success:hover {
  border-color: $qp-color-success !important;
  color: $qp-color-success !important;
}

.btn-outline-success:not([disabled]):not(.disabled).active,
.btn-outline-success:not([disabled]):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  background-color: $qp-color-success !important;
  color: $qp-color-white !important;
}

.btn-outline-info {
  border-color: $qp-color-info !important;
  color: $qp-color-info-dark-1 !important;

  .batch-icon {
    color: $qp-color-info-dark-1 !important;
  }
}

.btn-outline-info:focus,
.btn-outline-info:hover {
  border-color: $qp-color-info !important;
  color: $qp-color-info !important;
}

.btn-outline-info:not([disabled]):not(.disabled).active,
.btn-outline-info:not([disabled]):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  background-color: $qp-color-info !important;
  color: $qp-color-white !important;
}

.btn-outline-warning {
  border-color: $qp-color-warning !important;
  color: $qp-color-warning-dark-1 !important;

  .batch-icon {
    color: $qp-color-warning-dark-1 !important;
  }
}

.btn-outline-warning:focus,
.btn-outline-warning:hover {
  border-color: $qp-color-warning !important;
  color: $qp-color-warning !important;
}

.btn-outline-warning:not([disabled]):not(.disabled).active,
.btn-outline-warning:not([disabled]):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  background-color: $qp-color-warning !important;
  color: $qp-color-white !important;
}

.btn-outline-danger {
  border-color: $qp-color-danger !important;
  color: $qp-color-danger-dark-1 !important;

  .batch-icon {
    color: $qp-color-danger-dark-1 !important;
  }
}

.btn-outline-danger:focus,
.btn-outline-danger:hover {
  border-color: $qp-color-danger !important;
  color: $qp-color-danger !important;
}

.btn-outline-danger:not([disabled]):not(.disabled).active,
.btn-outline-danger:not([disabled]):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  background-color: $qp-color-danger !important;
  color: $qp-color-white !important;
}

.btn-outline-light:focus,
.btn-outline-light:hover {
  border: 1px solid $qp-color-secondary-dark-2;
  color: $qp-color-secondary-dark-2 !important;
}

.btn-outline-light:not([disabled]):not(.disabled).active,
.btn-outline-light:not([disabled]):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  background-color: $qp-color-secondary-dark-2 !important;
  color: $qp-color-white !important;
}

.btn[class*="btn-outline-"] {
  padding: calc(0.95rem + -1px) calc(2.13rem + -1px) calc(0.85rem + -1px) !important;
  border-width: 1px !important;
  @include qp-transition(opacity 0.225s ease-in-out);
}

.btn[class*="btn-outline-"]:hover,
.btn[class*="btn-outline-"]:focus {
  opacity: 0.7;
}

.btn[class*="btn-outline-"][class*="btn-sm"] {
  padding: calc(0.5rem + -1px) calc(1.6rem + -1px) !important;
  border-width: 1px !important;
  @include qp-transition(opacity 0.225s ease-in-out);
}

.btn[class*="btn-outline-"][class*="btn-lg"] {
  padding: calc(1rem + -1px) calc(2.4rem + -1px) !important;
  border-width: 1px !important;
  @include qp-transition(opacity 0.225s ease-in-out);
}

.btn-group-lg > .btn {
  padding: 1rem 2.4rem;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  margin-right: 2px;
}
// BUTTONS - Gradients
// .btn-gradient:after{
// position: absolute;
// content: ' ';
// top: 0;
// left: 0;
// width: 100%;
// height: 100%;
// z-index: 2;
// opacity: 0;
// }
// .
// .btn-gradient:after{
// position: absolute;
// content: ' ';
// top: 0;
// left: 0;
// width: 100%;
// height: 100%;
// z-index: 2;
// opacity: 0;
// }

.btn-primary.btn-gradient {
  @include qp-gradient(
    $qp-color-primary-gradient-1,
    $qp-color-primary-gradient-2
  );
}

.btn-primary.btn-gradient.dropdown-toggle:hover,
.btn-primary.btn-gradient.focus,
.btn-primary.btn-gradient:focus,
.btn-primary.btn-gradient:hover {
  background-color: $qp-color-primary-gradient-2 !important;
}

.btn-primary.btn-gradient:after {
  @include qp-gradient(
    $qp-color-primary-gradient-2,
    $qp-color-primary-gradient-2
  );
}

.btn-primary.btn-gradient:hover:after {
  opacity: 1;
}

.btn-primary.btn-gradient span.gradient {
  position: relative;
  z-index: 3;
}

.btn-primary.btn-gradient:not([disabled]):not(.disabled).active,
.btn-primary.btn-gradient:not([disabled]):not(.disabled):active,
.show > .btn-primary.btn-gradient.dropdown-toggle {
  @include qp-gradient(
    $qp-color-primary-gradient-2,
    $qp-color-primary-gradient-2
  );
}

.btn-primary.btn-gradient:active:hover:after {
  @include qp-gradient(0, 0);
}

.btn-primary.btn-gradient.disabled:hover,
.btn-primary.btn-gradient:disabled:hover {
  background-color: #000;
  @include qp-gradient(
    $qp-color-primary-gradient-1,
    $qp-color-primary-gradient-2
  );
}

.btn-primary.btn-gradient.disabled:hover:after,
.btn-primary.btn-gradient:disabled:hover:after {
  display: none;
}

.btn-secondary.btn-gradient {
  @include qp-gradient(
    $qp-color-secondary-gradient-1,
    $qp-color-secondary-gradient-2
  );
}

.btn-secondary.btn-gradient.dropdown-toggle:hover,
.btn-secondary.btn-gradient.focus,
.btn-secondary.btn-gradient:focus,
.btn-secondary.btn-gradient:hover {
  background-color: $qp-color-secondary-gradient-2 !important;
}

.btn-secondary.btn-gradient:after {
  @include qp-gradient(
    $qp-color-secondary-gradient-2,
    $qp-color-secondary-gradient-2
  );
}

.btn-secondary.btn-gradient:hover:after {
  opacity: 1;
}

.btn-secondary.btn-gradient span.gradient {
  position: relative;
  z-index: 3;
}

.btn-secondary.btn-gradient:not([disabled]):not(.disabled).active,
.btn-secondary.btn-gradient:not([disabled]):not(.disabled):active,
.show > .btn-secondary.btn-gradient.dropdown-toggle {
  @include qp-gradient(
    $qp-color-secondary-gradient-2,
    $qp-color-secondary-gradient-2
  );
}

.btn-secondary.btn-gradient:active:hover:after {
  @include qp-gradient(0, 0);
}

.btn-secondary.btn-gradient.disabled:hover,
.btn-secondary.btn-gradient:disabled:hover {
  background-color: #000;
  @include qp-gradient(
    $qp-color-secondary-gradient-1,
    $qp-color-secondary-gradient-2
  );
}

.btn-secondary.btn-gradient.disabled:hover:after,
.btn-secondary.btn-gradient:disabled:hover:after {
  display: none;
}

.btn-yellow.btn-gradient {
  color: $qp-color-yellow-dark-2 !important;
  @include qp-gradient(
    $qp-color-yellow-gradient-1,
    $qp-color-yellow-gradient-2
  );
}

.btn-yellow.btn-gradient.dropdown-toggle:hover,
.btn-yellow.btn-gradient.focus,
.btn-yellow.btn-gradient:focus,
.btn-yellow.btn-gradient:hover {
  background-color: $qp-color-yellow-gradient-2 !important;
}

.btn-yellow.btn-gradient:after {
  @include qp-gradient(
    $qp-color-yellow-gradient-2,
    $qp-color-yellow-gradient-2
  );
}

.btn-yellow.btn-gradient:hover:after {
  opacity: 1;
}

.btn-yellow.btn-gradient span.gradient {
  position: relative;
  z-index: 3;
}

.btn-yellow.btn-gradient:not([disabled]):not(.disabled).active,
.btn-yellow.btn-gradient:not([disabled]):not(.disabled):active,
.show > .btn-yellow.btn-gradient.dropdown-toggle {
  @include qp-gradient(
    $qp-color-yellow-gradient-2,
    $qp-color-yellow-gradient-2
  );
}

.btn-yellow.btn-gradient:active:hover:after {
  @include qp-gradient(0, 0);
}

.btn-yellow.btn-gradient.disabled:hover,
.btn-yellow.btn-gradient:disabled:hover {
  background-color: #000;
  @include qp-gradient(
    $qp-color-yellow-gradient-1,
    $qp-color-yellow-gradient-2
  );
}

.btn-yellow.btn-gradient.disabled:hover:after,
.btn-yellow.btn-gradient:disabled:hover:after {
  display: none;
}

.btn-blue.btn-gradient {
  @include qp-gradient($qp-color-blue-gradient-1, $qp-color-blue-gradient-2);
  span.gradient {
    position: relative;
    z-index: 3;
  }
}

.btn-blue.btn-gradient.dropdown-toggle:hover,
.btn-blue.btn-gradient.focus,
.btn-blue.btn-gradient:focus,
.btn-blue.btn-gradient:hover {
  background-color: $qp-color-blue-gradient-2 !important;
}

.btn-blue.btn-gradient:after {
  @include qp-gradient($qp-color-blue-gradient-2, $qp-color-blue-gradient-2);
}

.btn-blue.btn-gradient:hover:after {
  opacity: 1;
}

.btn-blue.btn-gradient:not([disabled]):not(.disabled).active,
.btn-blue.btn-gradient:not([disabled]):not(.disabled):active,
.show > .btn-blue.btn-gradient.dropdown-toggle {
  @include qp-gradient($qp-color-blue-gradient-2, $qp-color-blue-gradient-2);
}

.btn-blue.btn-gradient:active:hover:after {
  @include qp-gradient(0, 0);
}

.btn-blue.btn-gradient.disabled:hover,
.btn-blue.btn-gradient:disabled:hover {
  background-color: #000;
  @include qp-gradient($qp-color-blue-gradient-1, $qp-color-blue-gradient-2);
}

.btn-blue.btn-gradient.disabled:hover:after,
.btn-blue.btn-gradient:disabled:hover:after {
  display: none;
}

.btn-red.btn-gradient {
  @include qp-gradient($qp-color-red-gradient-1, $qp-color-red-gradient-2);
  span.gradient {
    position: relative;
    z-index: 3;
  }
}

.btn-red.btn-gradient.dropdown-toggle:hover,
.btn-red.btn-gradient.focus,
.btn-red.btn-gradient:focus,
.btn-red.btn-gradient:hover {
  background-color: $qp-color-red-gradient-2 !important;
}

.btn-red.btn-gradient:after {
  @include qp-gradient($qp-color-red-gradient-2, $qp-color-red-gradient-2);
}

.btn-red.btn-gradient:hover:after {
  opacity: 1;
}

.btn-red.btn-gradient:not([disabled]):not(.disabled).active,
.btn-red.btn-gradient:not([disabled]):not(.disabled):active,
.show > .btn-red.btn-gradient.dropdown-toggle {
  @include qp-gradient($qp-color-red-gradient-2, $qp-color-red-gradient-2);
}

.btn-red.btn-gradient:active:hover:after {
  @include qp-gradient(0, 0);
}

.btn-red.btn-gradient.disabled:hover,
.btn-red.btn-gradient:disabled:hover {
  background-color: #000;
  @include qp-gradient($qp-color-red-gradient-1, $qp-color-red-gradient-2);
}

.btn-red.btn-gradient.disabled:hover:after,
.btn-red.btn-gradient:disabled:hover:after {
  display: none;
}

.btn-purple.btn-gradient {
  @include qp-gradient(
    $qp-color-purple-gradient-1,
    $qp-color-purple-gradient-2
  );
  span.gradient {
    position: relative;
    z-index: 3;
  }
}

.btn-purple.btn-gradient.dropdown-toggle:hover,
.btn-purple.btn-gradient.focus,
.btn-purple.btn-gradient:focus,
.btn-purple.btn-gradient:hover {
  background-color: $qp-color-purple-gradient-2 !important;
}

.btn-purple.btn-gradient:after {
  @include qp-gradient(
    $qp-color-purple-gradient-2,
    $qp-color-purple-gradient-2
  );
}

.btn-purple.btn-gradient:hover:after {
  opacity: 1;
}

.btn-purple.btn-gradient:not([disabled]):not(.disabled).active,
.btn-purple.btn-gradient:not([disabled]):not(.disabled):active,
.show > .btn-purple.btn-gradient.dropdown-toggle {
  @include qp-gradient(
    $qp-color-purple-gradient-2,
    $qp-color-purple-gradient-2
  );
}

.btn-purple.btn-gradient:active:hover:after {
  @include qp-gradient(0, 0);
}

.btn-purple.btn-gradient.disabled:hover,
.btn-purple.btn-gradient:disabled:hover {
  background-color: #000;
  @include qp-gradient(
    $qp-color-purple-gradient-1,
    $qp-color-purple-gradient-2
  );
}

.btn-purple.btn-gradient.disabled:hover:after,
.btn-purple.btn-gradient:disabled:hover:after {
  display: none;
}

.btn-orange.btn-gradient {
  @include qp-gradient(
    $qp-color-orange-gradient-1,
    $qp-color-orange-gradient-2
  );
  span.gradient {
    position: relative;
    z-index: 3;
  }
}

.btn-orange.btn-gradient.dropdown-toggle:hover,
.btn-orange.btn-gradient.focus,
.btn-orange.btn-gradient:focus,
.btn-orange.btn-gradient:hover {
  background-color: $qp-color-orange-gradient-2 !important;
}

.btn-orange.btn-gradient:after {
  @include qp-gradient(
    $qp-color-orange-gradient-2,
    $qp-color-orange-gradient-2
  );
}

.btn-orange.btn-gradient:hover:after {
  opacity: 1;
}

.btn-orange.btn-gradient:not([disabled]):not(.disabled).active,
.btn-orange.btn-gradient:not([disabled]):not(.disabled):active,
.show > .btn-orange.btn-gradient.dropdown-toggle {
  @include qp-gradient(
    $qp-color-orange-gradient-2,
    $qp-color-orange-gradient-2
  );
}

.btn-orange.btn-gradient:active:hover:after {
  @include qp-gradient(0, 0);
}

.btn-orange.btn-gradient.disabled:hover,
.btn-orange.btn-gradient:disabled:hover {
  background-color: #000;
  @include qp-gradient(
    $qp-color-orange-gradient-1,
    $qp-color-orange-gradient-2
  );
}

.btn-orange.btn-gradient.disabled:hover:after,
.btn-orange.btn-gradient:disabled:hover:after {
  display: none;
}

.btn-green.btn-gradient {
  @include qp-gradient($qp-color-green-gradient-1, $qp-color-green-gradient-2);
  span.gradient {
    position: relative;
    z-index: 3;
  }
}

.btn-green.btn-gradient.dropdown-toggle:hover,
.btn-green.btn-gradient.focus,
.btn-green.btn-gradient:focus,
.btn-green.btn-gradient:hover {
  background-color: $qp-color-green-gradient-2 !important;
}

.btn-green.btn-gradient:after {
  @include qp-gradient($qp-color-green-gradient-2, $qp-color-green-gradient-2);
}

.btn-green.btn-gradient:hover:after {
  opacity: 1;
}

.btn-green.btn-gradient:not([disabled]):not(.disabled).active,
.btn-green.btn-gradient:not([disabled]):not(.disabled):active,
.show > .btn-green.btn-gradient.dropdown-toggle {
  @include qp-gradient($qp-color-green-gradient-2, $qp-color-green-gradient-2);
}

.btn-green.btn-gradient:active:hover:after {
  @include qp-gradient(0, 0);
}

.btn-green.btn-gradient.disabled:hover,
.btn-green.btn-gradient:disabled:hover {
  background-color: #000;
  @include qp-gradient($qp-color-green-gradient-1, $qp-color-green-gradient-2);
}

.btn-green.btn-gradient.disabled:hover:after,
.btn-green.btn-gradient:disabled:hover:after {
  display: none;
}

.btn-group > .btn + .btn.dropdown-toggle-split {
  border-left: 1px solid rgba(255, 255, 255, 0.43) !important;
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn-group-vertical {
  > .btn {
    margin: 0;
  }

  > .btn:first-child:not(:last-child) {
    margin: 0;
  }

  > *:not(:last-child) {
    margin-bottom: 2px !important;
  }
}

.btn-group.btn-group-toggle.btn-group-custom-checkbox {
  .btn::before {
    content: "\f020";
    font-weight: 400;
    font-size: 1.3rem;
    position: relative;
    margin-right: 5px;
    line-height: 0.9;
    top: 1px;
  }

  .btn.active::before {
    content: "\f164";
  }
}

.btn-group.btn-group-toggle.btn-group-custom-radio {
  .btn::before {
    content: "\F0C6";
    font-weight: 400;
    font-size: 1.3rem;
    position: relative;
    margin-right: 5px;
    line-height: 0.9;
    top: 1px;
  }

  .btn.active::before {
    content: "\F0C7";
  }
}

.btn .batch-icon {
  color: $qp-color-white;
  line-height: 1;
}

.btn-block {
  margin: auto;
}

.dropdown-item .batch-icon {
  color: $qp-color-secondary-gradient-2;
  // padding-right: 5px;
}
/* Forms */
label {
  font-family: $regular-font;
}

.custom-file-input,
.form-control {
  background-color: $qp-color-white !important;
  padding: 0.6rem 0.75rem;
  margin-top: 0;
  border: 1px solid $qp-color-grey;
}

.custom-file {
  height: calc(3.25rem + 2px);
}

.custom-file-input,
.form-control-file {
  height: calc(3.25rem + 2px);
}

.custom-file-label {
  padding: 1.375rem 0.75rem;
  line-height: 0.8;
  height: calc(3.25rem + 2px);
  border-color: $qp-color-grey;
  box-shadow: 0 1px 0 0 $qp-color-grey;
  @include qp-border-radius(5px);
}

.custom-file-label::after {
  line-height: 2.5;
  height: calc(calc(3.25rem + 2px) - 1px * 2);
}

.form-control-sm {
  height: 1.4rem !important;
}

.form-control-lg {
  height: 3rem !important;
}

select.custom-select,
select.form-control {
  border-color: $qp-color-grey;
  box-shadow: 0 1px 0 0 $qp-color-grey;
}

select.custom-select:focus,
select.form-control:focus {
  border-color: $qp-color-primary;
  box-shadow: 0 1px 0 0 $qp-color-primary;
}

select.custom-select:not([size]):not([multiple]),
select.form-control:not([size]):not([multiple]) {
  height: calc(3.199rem + 2px);
}

.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-text:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(4.199rem + 2px) !important;
}

.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-text:not([size]):not([multiple]),
select.form-control-sm:not([size]):not([multiple]) {
  height: calc(2.199rem + 2px) !important;
}

textarea.form-control {
  border-color: $qp-color-grey;
  box-shadow: 0 1px 0 0 $qp-color-grey;
  min-height: 10rem;
  @include qp-border-radius(5px);
  @include qp-transition(all 0.225s ease-in-out !important);
}

textarea.form-control:focus {
  // border-color: $qp-color-primary;
  // background-color: $qp-color-primary;
  // box-shadow: 0 1px 0 0 $qp-color-primary;
  min-height: 10rem;
  @include qp-border-radius(5px);
  @include qp-transition(all 0.225s ease-in-out !important);
}

.form-group {
  margin-bottom: 18px;

  .form-control {
    @include qp-border-radius(5px);
  }

  input.form-control {
    width: calc(100% - 1.2rem - 2px);
  }

  small {
    font-weight: 300;
  }
}

.form-group.row,
.form-row {
  display: flex;

  .btn {
    margin: 0 $qp-default-padding 0 0;
  }
}

.form-inline {
  .form-group {
    input.form-control {
      width: auto;
      margin-bottom: 0;
    }
  }

  .btn {
    margin: 0 $qp-default-padding 0 0;
  }
}

.form-check {
  input:disabled + .form-check-label {
    opacity: 0.7;
    font-style: italic;
    font-weight: 300 !important;
    cursor: not-allowed;
  }

  .form-check-label {
    margin-left: 3px;
    margin-right: 10px;
  }
}

.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: $qp-color-success !important;
}

.custom-select.is-valid:focus,
.form-control.border-success,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  border-color: $qp-color-success !important;
  box-shadow: 0 1px 0 0 $qp-color-success;
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: $qp-color-danger !important;
}

.custom-select.is-invalid:focus,
.form-control.border-danger,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: $qp-color-danger !important;
  box-shadow: 0 1px 0 0 $qp-color-danger;
}

.form-control.border-priamry {
  border-color: $qp-color-primary !important;
  box-shadow: 0 1px 0 0 $qp-color-primary;
}

.form-control.border-secondary {
  border-color: $qp-color-secondary !important;
  box-shadow: 0 1px 0 0 $qp-color-secondary;
}

.form-control.border-warning {
  border-color: $qp-color-warning !important;
  box-shadow: 0 1px 0 0 $qp-color-warning;
}

.form-control.border-info {
  border-color: $qp-color-info !important;
  box-shadow: 0 1px 0 0 $qp-color-info;
}

.form-text {
  font-weight: 300;
}

.col-form-label {
  line-height: 3.3;
}

.col-form-label-lg {
  line-height: 3.3;
}

.input-group {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  .input-group-append,
  .input-group-prepend {
    .btn {
      margin: 0;
    }

    .btn + .btn {
      margin-left: 1px;
    }

    .btn-outline-primary,
    .btn-primary {
      box-shadow: 0 1px 0 0 $qp-color-primary !important;
    }

    .btn-outline-secondary,
    .btn-secondary {
      box-shadow: 0 1px 0 0 $qp-color-secondary !important;
    }

    .btn-outline-success,
    .btn-success {
      box-shadow: 0 1px 0 0 $qp-color-success !important;
    }

    .btn-info,
    .btn-outline-info {
      box-shadow: 0 1px 0 0 $qp-color-info !important;
    }

    .btn-outline-warning,
    .btn-warning {
      box-shadow: 0 1px 0 0 $qp-color-warning !important;
    }

    .btn-danger,
    .btn-outline-danger {
      box-shadow: 0 1px 0 0 $qp-color-danger !important;
    }

    .input-group-text {
      border-color: $qp-color-grey;
      background-color: $qp-color-grey;
      box-shadow: 0 1px 0 0 $qp-color-grey;
      @include qp-transition(all 0.225s ease-in-out !important);

      .batch-icon {
        @include qp-transition(all 0.225s ease-in-out !important);
      }
    }

    .dropdown-toggle-split {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .input-group-prepend {
    .input-group-text {
      @include qp-border-radius-top-right(0);
      @include qp-border-radius-bottom-right(0);
      @include qp-border-radius-top-left(5px);
      @include qp-border-radius-bottom-left(5px);
    }

    .input-group-text + .input-group-text {
      // padding-left: 0;
      margin-left: 1px;
    }
  }

  .input-group-append {
    .input-group-text {
      @include qp-border-radius-top-right(5px);
      @include qp-border-radius-bottom-right(5px);
      @include qp-border-radius-top-left(0);
      @include qp-border-radius-bottom-left(0);

      .batch-icon {
        color: $qp-color-text;
      }
    }

    .input-group-text:not(:last-child) {
      margin-right: 2px;
    }
  }
}

.input-group.focus {
  .input-group-text {
    color: $qp-color-white;
    background-color: $qp-color-primary;
    border-color: $qp-color-primary;
    box-shadow: 0 1px 0 0 $qp-color-primary;

    .batch-icon {
      color: $qp-color-white;
    }
  }
}

.input-group.short-input-field {
  > input {
    width: 20px;
  }

  > .input-group-append,
  > .input-group-prepend {
    .btn {
      padding-left: 1.35rem !important; // padding-right: 1rem !important;
      padding-right: 1.35rem !important;
    }
  }
}

.input-group.input-group-lg {
  .form-control {
    height: 3.1rem;
  }
}

.form-control:disabled,
.form-control[readonly],
.form-control[readonly]:focus {
  border: 1px solid $qp-color-grey;
  box-shadow: 0 1px 0 0 $qp-color-grey;
  background-color: lighten($qp-color-grey, 10%) !important;
}

.form-control:disabled {
  text-decoration: line-through;
}

input[type="date"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search-md"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
textarea.md-textarea {
  width: calc(100% - 1.5rem);
  border: 1px solid $qp-color-grey;
  box-shadow: 0 1px 0 0 $qp-color-grey;
  @include qp-border-radius(5px);
}

.form-control-plaintext {
  border: transparent !important;
  box-shadow: none !important;
  padding-top: 11px;
}

input[type="date"]:focus:not([readonly]),
input[type="datetime-local"]:focus:not([readonly]),
input[type="email"]:focus:not([readonly]),
input[type="number"]:focus:not([readonly]),
input[type="password"]:focus:not([readonly]),
input[type="search"]:focus:not([readonly]),
input[type="search-md"]:focus:not([readonly]),
input[type="tel"]:focus:not([readonly]),
input[type="text"]:focus:not([readonly]),
input[type="time"]:focus:not([readonly]),
input[type="url"]:focus:not([readonly]),
textarea.md-textarea:focus:not([readonly]),
textarea:focus:not([readonly]) {
  border: 1px solid $qp-color-primary;
  box-shadow: 0 1px 0 0 $qp-color-primary;
  // height: 2.1rem;
}

input[type="date"].valid,
input[type="date"]:focus.valid,
input[type="datetime-local"].valid,
input[type="datetime-local"]:focus.valid,
input[type="email"].valid,
input[type="email"]:focus.valid,
input[type="number"].valid,
input[type="number"]:focus.valid,
input[type="password"].valid,
input[type="password"]:focus.valid,
input[type="search-md"].valid,
input[type="search-md"]:focus.valid,
input[type="search"].valid,
input[type="search"]:focus.valid,
input[type="tel"].valid,
input[type="tel"]:focus.valid,
input[type="text"].valid,
input[type="text"]:focus.valid,
input[type="time"].valid,
input[type="time"]:focus.valid,
input[type="url"].valid,
input[type="url"]:focus.valid,
textarea.md-textarea.valid,
textarea.md-textarea:focus.valid {
  border: 1px solid $qp-color-success;
  box-shadow: $qp-color-success;
}

.custom-control {
  margin-right: 1rem;

  .custom-control-label {
    margin-left: 0.5rem;
    margin-top: 0.3rem;
    cursor: pointer;
  }

  .custom-control-indicator,
  .custom-control-label::before {
    width: 1.5rem;
    height: 1.5rem; // top: 12px; // left: 25px; // @include qp-transition(background-color .225s ease-in-out !important);
    @include qp-transition(opacity 0.225s ease-in-out !important);
  }

  .custom-control-label::after {
    width: 1.3rem;
    height: 1.3rem; // top: 4px; // left: 1px;
    opacity: 0;
    @include qp-transition(opacity 0.225s ease-in-out !important);
  }

  .custom-control-input:disabled ~ .custom-control-label {
    opacity: 0.5;
    text-decoration: line-through;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    opacity: 1;
  }

  .custom-control-indicator + .custom-control-description {
    margin-top: 0.3rem;
    display: inline-block;
    margin-left: 0.5rem;
    @include qp-transition(all 0.225s ease-in-out !important);
  }

  .custom-control-input:checked ~ .custom-control-indicator {
    background-color: $qp-color-primary;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  top: 5px;
  left: 1px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  top: 5px;
  left: 1px;
}

.custom-control-input.is-valid:checked ~ .custom-control-label::after,
.was-validated
  .custom-checkbox.custom-control-input:valid:checked
  ~ .custom-control-label::after {
  background-color: $qp-color-success;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $qp-color-primary;
}

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-position: center center;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $qp-color-primary;
}

.custom-control.custom-color-control {
  min-width: 2.5rem;
  min-height: 2.5rem;
  padding-left: 2rem;
  cursor: pointer;
  display: inline-block;

  .custom-control-label::before {
    width: 2.5rem;
    height: 2.5rem;
  }

  .custom-control-label::after {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.custom-radio.custom-color-control
  .custom-control-input:checked
  ~ .custom-control-label::after {
  top: 9px;
  left: 6px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.invalid-feedback {
  margin-left: 0.5rem;
}
/* Progress Bar */
.progress {
  height: auto;
  margin-bottom: 1rem;

  .progress-bar {
    font-size: 0.7rem;
    height: 0.9rem;
    background-color: $qp-color-primary;
    @include qp-transition(width 0.3s ease-in-out);
  }
}

.progress .progress-bar.progress-bar-sm {
  height: 3px;
}

.progress .progress-bar.progress-bar-lg {
  height: 20px;
}
/* Pagination */
.page-item {
  margin-left: 2px;
}

.page-link {
  color: $qp-color-primary;
  padding: 0.75rem 1.083rem;
}

.page-item.active {
  .page-link {
    background-color: $qp-color-primary;
    box-shadow: none !important;
  }
}

.pagination {
  .page-link {
    font-size: 1rem;
  }
}

.pagination-lg {
  .page-item {
    .page-link {
      padding: 1rem 2rem;
    }
  }

  .page-item.active {
    .page-link {
      @include qp-border-radius(5px);
    }
  }
}

.pager.wizard {
  padding: 0;
  margin: 0;
  list-style: none;
}

.pager li.previous {
  float: left;
}

.pager li.next {
  float: right;
}

.pager li > a,
.pager li > span {
  font-weight: 500;
  line-height: 1.25;
  text-transform: uppercase;
  box-shadow: none !important;
  @include qp-button-size-md($qp-active-font);
  @include qp-button-padding($qp-active-font);
}

.pager li > a:focus,
.pager li > a:hover,
.pager li > span:focus,
.pager li > span:hover {
  /* opacity: 0.8; */
}

.pager li.last > a,
.pager li.last > span,
.pager li.next > a,
.pager li.next > span {
  background: #1f91f3;
  color: #ffffff;
}

.pager li.last > a:focus,
.pager li.last > a:hover,
.pager li.last > span:focus,
.pager li.last > span:hover,
.pager li.next > a:focus,
.pager li.next > a:hover,
.pager li.next > span:focus,
.pager li.next > span:hover {
  background: #005ba9;
}

.pager li.disabled > a,
.pager li.disabled > span {
  background: none;
  box-shadow: none;
  color: #888888;
}
/* Modal */
.modal {
  z-index: 9002;
}

.modal-backdrop {
  z-index: 9000;
}

.modal-dialog .modal-content {
  box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06);
  @include qp-border-radius();
}
/* Lists */
.list-group {
  .list-group-item.active {
    background-color: $qp-color-primary;
    border-color: $qp-color-primary;

    * {
      color: $qp-color-white;
    }
  }

  .list-group-item-success {
    // background-color: fade($qp-color-success, 60%); // background-color: fade-out($qp-color-success, 0.7);
  }

  .list-group-item.disabled,
  .list-group-item:disabled {
    color: $qp-color-grey; // opacity: 0.5;
  }

  .list-group-item-action {
    color: $qp-color-text;
  }

  .list-group-item-action.active {
    color: $qp-color-white;
  }
}
/* Main QuillPro Styles */
/* Right Column - General */
// Misc Classes

.subtext {
  font-size: 0.9rem;
  font-weight: 300;
}

.strike-out {
  color: #999999;
  text-decoration: line-through;
}

.clickable {
  cursor: pointer;
  @include qp-transition(
    color 0.225s ease-in-out,
    background-color 0.225s ease-in-out !important
  );
}

.clickable:focus,
.clickable:hover {
  // opacity: 0.7;
  background-color: $qp-hover-highlight;
}
/* Right Column */
.right-column {
  width: 100% !important;
  // width: calc(100% - 290px);
  // margin-left: 290px;
  padding-bottom: 100px;
  @include qp-transition(margin-left 0.225s ease-in-out !important);
  @include mobile-tablet {
    padding-bottom: 0 !important;
    //  margin-left: 20px;
    height: 100%;
  }
}

.sidebar + .right-column {
  margin-left: 0;
}
/* Profile Pictures */
.profile-picture {
  width: 48px;
  height: 48px;
  padding-top: 2px;
  @include qp-border-radius(50%);
  position: relative;

  img {
    display: block;
    width: 44px;
    height: 44px;
    @include qp-border-radius(50%);
    border: 2px solid $qp-color-white;
    margin: 0 auto;
  }
}

.profile-picture.has-message::after {
  font-size: 2.6rem;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
  content: "\F140";
  color: $qp-color-primary;
  position: absolute;
  top: -5px;
  left: -10px;
}

.profile-picture.profile-picture-lg {
  width: 150px;
  height: 150px;
  margin: 0 auto;

  img {
    width: 144px;
    height: 144px;
    margin: 1px auto 0;
    border: 5px solid $qp-color-white;
  }
}

.profile-picture.profile-picture-lg.has-message::after {
  top: -3px;
  left: -7px;
  font-size: 4.6rem;
}
/* Custom Scrollbar */
.mCSB_inside > .mCSB_container {
  margin-right: 0;
}

.mCSB_scrollTools .mCSB_draggerContainer {
  left: -10px;
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: $qp-color-secondary-light-3;
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: $qp-color-secondary-light-1;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_dragger
  .mCSB_dragger_bar {
  width: 8px;
}

.mCSB_draggerRail,
.mCSB_dragger_bar {
  @include qp-transition(width 0.225s ease-in-out !important);
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_draggerRail {
  width: 4px;
}
/* Charts */
canvas {
  // width: 10px;
  // height: 10px;
}
// Easy Pie Chart

.chart-epc {
  position: relative;
  text-align: center;
  height: 100px;
  width: 100px;
  margin: 0 auto;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }

  .percent {
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
    margin-left: 6px;
    z-index: 2;
  }

  .percent:after {
    content: "%";
    font-size: 0.6em;
  }
}

.chart-controls {
  text-align: center;
}
/* Map */
.jqvmap-zoomin,
.jqvmap-zoomout {
  width: 20px;
  height: 20px;
  padding: 5px 5px 5px 4px;
  background-color: $qp-color-primary;
  @include qp-border-radius();
}

.jqvmap-zoomout {
  top: 35px;
}

.jqvmap-zoomin:focus,
.jqvmap-zoomin:hover,
.jqvmap-zoomout:focus,
.jqvmap-zoomout:hover {
  background-color: $qp-color-primary-light-1 !important;
}

.jqvmap-zoomin:active,
.jqvmap-zoomout:active {
  background-color: $qp-color-primary-dark-1 !important;
}
/* Search Results */
.search-results-list {
  .search-link {
    color: $qp-color-success-dark-1;
  }
}
/* Tags Input */
.bootstrap-tagsinput {
  display: block;
  padding-top: 8px;
  box-shadow: 0 1px 0 0 $qp-color-grey;

  .tag.badge {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .twitter-typeahead {
    top: -3px;
    border: 0 !important;
    box-shadow: none !important;

    input {
      color: $qp-color-text;
      border: 0 !important;
      box-shadow: none !important;
    }

    .tt-menu {
      width: 20rem;
      font-size: 1rem;
      margin: 0;
      padding: 0.5rem 0;
      min-width: 10rem;
      background-color: $qp-color-white;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
        0 2px 10px 0 rgba(0, 0, 0, 0.12);

      .tt-dataset {
        .tt-suggestion {
          color: $qp-color-secondary-gradient-2 !important;
          cursor: pointer;
          text-transform: uppercase;
          padding: 10px 1.5rem !important;
        }
      }
    }
  }

  input {
    color: $qp-color-text;
    border: 0 !important;
    box-shadow: none !important;
  }
}
/* Datatables */
.dataTables_filter {
  label {
    width: 100%;
  }
}
/* Notifications - Toastr */
#toast-container {
  > .toast {
    padding: 15px 15px 15px 55px;
    background-color: $qp-color-primary;
    background-image: none !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  }

  > .toast::before {
    font-weight: 300;
    position: absolute;
    font-size: 2.5rem;
    left: 15px;
    top: 20px;
  }

  > .toast.toast-success {
    background-color: $qp-color-success;
  }

  > .toast.toast-success::before {
    content: "\f164";
  }

  > .toast.toast-info {
    background-color: $qp-color-primary;
  }

  > .toast.toast-info::before {
    content: "\f12D";
  }

  > .toast.toast-warning {
    color: $qp-color-secondary;
    background-color: $qp-color-warning;
  }

  > .toast.toast-warning::before {
    content: "\f15A";
  }

  > .toast.toast-error {
    background-color: $qp-color-danger;
  }

  > .toast.toast-error::before {
    content: "\f093";
  }
}
/* Carousel */
.carousel {
  box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06);
  @include qp-transition(
    box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) !important
  );

  .carousel-inner {
    .carousel-item {
      img {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    .batch-icon {
      color: $qp-color-primary;
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      background-image: none;
    }
  }
}

.carousel:focus,
.carousel:hover {
  box-shadow: 0 9px 23px rgba(0, 0, 0, 0.18), 0 5px 5px rgba(0, 0, 0, 0.12);
}
/* Ecommerce Pages */
.category-page,
.product-page {
  h1 {
    line-height: 1.1;
  }

  .price-block {
    font-size: 2.5rem;

    .price-new {
      float: left;
      font-weight: 500;
    }

    .price-old {
      float: left;
      font-size: 60%;
      color: $qp-color-danger;
      margin-top: 11px;
      text-decoration: line-through;
      margin-left: 10px;
    }

    .price-discount {
      padding-top: 0;
      font-size: 1rem;
      clear: both;
      color: $qp-color-success;
    }
  }

  .ratings {
    margin-bottom: 25px;

    .batch-icon {
      font-size: 2.6rem;
      color: $qp-color-secondary;
      cursor: pointer;
      margin: 0 -4px;
      opacity: 0.2;
    }

    .batch-icon.rating-highlighted {
      color: $qp-color-warning;
      opacity: 1;
    }

    .batch-icon:focus,
    .batch-icon:hover {
      color: $qp-color-warning;
      opacity: 0.5;
    }
  }
}

.category-page {
  .card {
    .card-body {
      .card-title {
        margin-bottom: 0;
      }

      .price-block {
        font-size: 1.5rem;

        .price-new {
          float: none;
          display: inline-block;
          font-weight: 500;
        }

        .price-old {
          float: none;
          display: inline-block;
          margin-top: 0;
          margin-left: 5px;
        }
      }

      .ratings {
        margin-bottom: 5px;
      }
    }
  }
}
/* Tooltips/Popover */
.popover {
  font-family: "Montserrat", sans-serif;
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 11px rgba(0, 0, 0, 0.12), 0 3px 5px rgba(0, 0, 0, 0.12) !important;
  z-index: 9999;
  s .popover-header {
    padding: 1rem 2rem 0.5rem;
  }

  .popover-body {
    padding: 1rem 2rem;
  }

  .popover-header:not(:empty) + .popover-body:not(:empty) {
    padding: 0.5rem 2rem 1rem;
  }
}

.tooltip {
  box-shadow: 0 7px 23px 0 rgba(0, 0, 0, 0.1), 0 13px 49px 0 rgba(0, 0, 0, 0.06) !important;
  z-index: 9999;
}
/* Responsiveness */
// Small devices (Not Mobile-first approach) (landscape phones, 576px and up)
@media (max-width: 575px) {
  #navbar-header-content {
    // flex-basis: auto !important;
  }

  .sidebar.open {
    left: 0;

    .hamburger {
      opacity: 1;
      right: -66px;
    }
  }

  .sidebar {
    .hamburger {
      opacity: 0;
      right: 0;
    }
  }

  .dropdown-menu.dropdown-menu-right {
    // left: auto !important;
    // right: 0 !important;
  }

  .sisu.right-column {
    .signin-left-column {
      .signin-logo {
        display: none;
      }
    }
  }

  #toast-container > div {
    width: 100%;
  }
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #navbar-header-content {
    // flex-basis: auto !important;
  }

  .sidebar.open {
    left: 0;

    .hamburger {
      opacity: 1;
      right: -66px;
    }
  }

  .sidebar {
    .hamburger {
      opacity: 0;
      right: 0;
    }
  }

  .dropdown-menu.dropdown-menu-right {
    // left: auto !important; // right: 0 !important;
  }

  .fc-toolbar .fc-left {
    float: left !important;
  }

  .fc-toolbar .fc-right {
    float: right !important;
  }
}
// To fix override by MDB Framework
@media (max-width: 600px) {
  .navbar .dropdown-menu.dropdown-menu-right {
    // right: 0 !important;
  }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .navbar {
    .navbar-nav.navbar-profile {
      .nav-item {
        .nav-link.dropdown-toggle {
          .profile-name {
            display: block;
          }
        }
      }
    }
  }

  .sidebar-horizontal .batch-icon {
    display: inline-block;
  }

  .dropdown-menu.dropdown-menu-right {
    // left: auto !important;
    // right: 0 !important;
  }
}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .navbar {
    .hamburger {
      display: none;
    }

    > .navbar-collapse {
      flex-basis: 100%;
    }

    .navbar-nav.navbar-profile {
      .nav-item {
        .nav-link.dropdown-toggle {
          padding: 0;
        }
      }
    }

    .navbar-nav:not(.navbar-notifications):not(.navbar-profile) {
      flex-direction: row;
    }
  }

  .sidebar,
  .sidebar.bg-dark {
    left: 0;
  }

  .sidebar-horizontal,
  .sidebar-horizontal.bg-dark {
    display: block;

    .navbar-nav {
      flex-direction: row;

      .nav-item {
        .nav-link.dropdown-toggle::after {
          float: none;
          margin-top: 0;
        }

        .dropdown-menu {
          position: absolute !important;
          width: 20rem;

          .dropdown-menu {
            // position: static !important;
            left: 0;
            top: 64%;
          }
        }
      }
    }
  }

  .sidebar + .right-column {
    margin-left: 225px;
  }

  .navbar-sidebar-horizontal.bg-dark.fixed-top
    + .right-column
    > .sidebar-horizontal.fixed-top
    + .main-content,
  .navbar-sidebar-horizontal.fixed-top
    + .right-column
    > .sidebar-horizontal.fixed-top
    + .main-content {
    padding-top: 127px !important;
  }
  .navbar-nav {
    float: left;
    flex-direction: row;

    .nav-item a {
      display: block;
    }
  }
}

.modal-header {
  background: $qp-color-primary;

  .modal-title {
    color: white;
  }
  span {
    color: white;
  }

  .nav-link {
    padding: 0;
  }
  // for billing info edit modal
  .nav-link.active {
    background: $qp-color-primary;
  }
}
// Extra large devices (large desktops, 1200px and up)

.date-height {
  height: calc(3.199rem + 2px);
  margin-bottom: 1em;
}

.font-size-1-2 {
  font-size: 1.2rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.main-span {
  color: $primary-color !important;
  background-color: $secondary-color;
}

.secondary-span {
  color: $secondary-color !important;
  background-color: $primary-color;
}

// .container {
//   margin-left:1rem !important;
//   margin-right: 1rem !important;
//   padding-right:1rem !important;
// }

.loading-logo {
  animation-name: fadeInWithZoom;
  animation-duration: 2s;
  animation-timing-function: ease;

  width: 40vw;
  max-width: 600px;
  height: 30vh;
  max-height: 500px;
  margin: 40vh auto 0 auto;

  background-image: url(/assets/img/logo-loading.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: translateY(-50%);
}

@keyframes fadeInWithZoom {
  0% {
    transform: scale(0.97) translateY(-50%);
    opacity: 0;
  }

  100% {
    transform: scale(1) translateY(-50%);
    opacity: 1;
  }
}

.btn-with-icons {
  width: 50px;
  height: 30px;
  line-height: 33px;
}

.selectedRow {
  // background: $secondary-color!important;
}

@media (min-width: 768px) {
  .ui.header.mbottom-10 {
    margin-bottom: 10px;
  }
  .ui.header.mtop-0 {
    margin-top: 0;
  }
  .ui.cards.mtop-0 {
    margin-top: 0;
  }
  .ui.grid.mtop-0 {
    margin-top: 0;
  }
}

.card-content-height {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
}

.table-container {
  overflow-x: auto;
  max-height: 600px;
  min-height: 200px;
  &.lease-table-listing {
    max-height: 440px;
    min-height: 200px;
  }
  .lead-table-list {
    th {
      position: sticky;
      top: 0;
      z-index: 10;
    }
  }
  &.px-0 {
    padding-left: 0;
    padding-right: 0;
    tr {
      &:nth-child(2) {
        th {
          background: #343c49;
          &::before {
            top: 50%;
            margin-top: -2.5px;
          }
          &:nth-child(4),
          &:nth-child(6) {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row-reverse;
            height: 57px;
            padding-right: 18px;
            &::before {
              right: 10px;
            }
          }
        }
      }
    }
  }
  &.py-10 {
    padding-top: 0;
    padding-bottom: 10px;
    margin-top: 20px;
  }
}

.search-wrapper-row {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: -46px;
  right: 0;
  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
}

.show-page-details {
  @media (max-width: 767px) {
    text-align: right;
    width: 100%;
    margin-top: 15px;
  }
}

.search-wrapper {
  width: 400px;
  position: relative;
  @media (max-width: 1100px) {
    width: 300px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
  input {
    width: 100%;
    height: 36px;
    line-height: 36px;
    padding-left: 40px;
    &:focus {
      outline: 0;
      border-color: #85b7d9;
    }
  }
  .search {
    position: absolute;
    top: 12px;
    left: 12px;
    opacity: 0.4;
  }
}

.relative {
  position: relative;
}

.lead-action-wrapper {
  padding-right: 415px;
  display: flex;
  align-items: center;
  @media (max-width: 1100px) {
    padding-right: 315px;
  }
}

.pagination-wrapper-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.paginate {
  &.new-pagination-style {
    margin-left: auto;
    button {
      margin: 2px 5px;
      height: 35px;
      width: 35px;
      border: 1px solid #e0e1e2;
      border-radius: 4px;
      background: none;
      cursor: pointer;
      color: #343c49;
      &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
        &:hover {
          background: none;
          color: #343c49;
          border-color: #e0e1e2;
        }
      }
      &:hover {
        background: $accent-color;
        color: #fff;
        border-color: $accent-color;
      }
      &:first-child {
        margin-right: 0;
      }
    }
    .pagination-active-button {
      background: $accent-color;
      color: #fff;
    }
    .margin-0 {
      margin: 0;
    }
  }
}

.modal-position {
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
}

.mt-10 {
  margin-top: 10px;
}

.lead-table-list {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  .react-select__control {
    background-color: transparent;
    border: 0;
    width: 30px;
    width: 16px;
    margin-right: 10px;
    cursor: pointer;
    .react-select__value-container,
    .react-select__clear-indicator,
    .react-select__indicator-separator {
      display: none;
    }
  }
  .react-select__option--is-selected {
    background-color: $accent-color;
    div {
      div {
        color: #fff !important;
      }
    }
  }
}

.react-select__menu {
  min-width: 130px;
}

.react-select__dropdown-indicator {
  background: url("/assets/img/filter.svg") no-repeat;
  background-size: 100%;
  margin-top: 2px;
  svg {
    display: none;
  }
}

.applied-filter .react-select__dropdown-indicator {
  background: url("/assets/img/filter-apply.svg") no-repeat;
  background-size: 100%;
}

.fixed_header {
  width: 400px;
  table-layout: fixed;
  border-collapse: collapse;
}

.fixed_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 60vh;
}

.fixed_header thead tr {
  display: block;
}

.fixed_header th,
.fixed_header td {
  padding: 5px;
  text-align: left;
  width: 200px;
}

.button-group-row {
  display: flex;
  align-self: center;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .ui {
    &.header {
      &:first-child {
        margin: 0;
      }
    }
  }
}

.app-content {
  background-color: #f8f8f8;
  .ui {
    &.breadcrumb {
      .section {
        color: rgb(127, 135, 147);
      }
      a {
        color: $accent-color;
        &:hover,
        &:focus,
        &:active {
          color: $accent-color;
        }
      }
    }
  }
}

.lead-profile-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .ui {
    &.header {
      margin-bottom: 0;
      font-size: 32px;
      text-transform: capitalize;
      color: #343c49;
      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    &.selection {
      &.dropdown {
        margin-left: 30px;
        @media (max-width: 767px) {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }
  }
}

.lead-profile-body {
  display: flex;
  align-items: flex-start;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .lead-profile-body-img {
    min-width: 140px;
    min-height: 140px;
    border-radius: 5px;
    background: rgb(228, 230, 231);
    display: flex;
    align-items: center;
    justify-content: center;
    .user {
      &.icon {
        font-size: 80px;
        color: rgb(174, 180, 183);
        margin: 0;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-color: rgba(0, 10, 0, 0.2);
    }
  }
  .lead-profile-info {
    padding-left: 40px;
    @media (max-width: 1199px) {
      padding-left: 20px;
    }
    @media (max-width: 767px) {
      padding-left: 0;
      margin-top: 15px;
    }
    h3 {
      &.ui {
        &.header {
          margin: 15px 0;
          font-size: 16px;
          color: #343c49;
          &:first-child {
            margin-top: 1px;
          }
          .icon {
            font-size: 16px;
            color: $accent-color;
            padding: 0;
            margin: 0 12px 0 0;
          }
          a {
            color: #343c49;
            &:hover,
            &:focus,
            &:active,
            :not(.btn) {
              color: #343c49;
            }
            .icon {
              font-size: 16px;
              color: $accent-color;
              padding: 0;
              margin: 0 12px 0 0;
            }
          }
        }
      }
    }
    h4 {
      &.ui {
        &.header {
          font-size: 15px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  .tour-scheduled {
    margin-left: auto;
    padding-left: 20px;
    p {
      white-space: nowrap;
    }
    @media (max-width: 767px) {
      margin-left: 0;
      margin-top: 15px;
      padding-left: 0;
    }
  }
}

.tour-scheduled {
  .ui {
    &.message {
      .tour-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        a {
          display: inline-block;
          margin-left: 15px;
          color: rgba(255, 0, 0, 0.6);
          &:not([href]):not([tabindex]) {
            color: rgba(255, 0, 0, 0.6);
          }
          .cancel {
            margin: 0;
          }
        }
      }
    }
  }
}

.ui {
  &.divider {
    &.lead-details-divider {
      margin: 25px 0;
    }
  }
  &.header {
    &.lead-derails-dropdown-row {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.lead-derails-dropdown-row {
  .ui {
    &.selection {
      &.dropdown {
        margin-top: 10px;
      }
    }
  }
}

.row {
  &.mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
}

.ui {
  &.grid {
    & > .row {
      & > .column {
        &.full-button-wrapper {
          display: flex;
          @media (max-width: 1000px) {
            flex-wrap: wrap;
          }
          .ui {
            &.ui {
              &.large {
                &.button {
                  flex: 1 1 auto;
                  font-size: 14px;
                  &:first-child {
                    margin-left: 0;
                  }
                  &:last-child {
                    margin-right: 0;
                  }
                  @media (max-width: 1220px) {
                    font-size: 13px;
                    padding: 13px 10px;
                  }
                  @media (max-width: 1000px) {
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ui {
  &.segment {
    &.requirements-preferences-box {
      padding: 25px 25px;
      margin: 25px 0 0 0;
      box-shadow: none;
      background: $primary-color-light;
      input[type="date"],
      input[type="number"],
      input[type="text"] {
        height: 38px;
        box-sizing: border-box;
        margin: 0;
        &:focus {
          border-color: $accent-color;
        }
      }
      .ui {
        &.header {
          color: rgba(0, 0, 0, 0.5);
          font-size: 16px;
          &.requirements-preferences-head {
            color: #343c49;
            font-size: 18px;
          }
        }
        &.selection {
          &.dropdown {
            min-width: 100%;
            padding-right: 30px;
          }
        }
        &.grid > .column:not(.row) {
          padding: 8px 10px;
        }
        &.positive {
          &.button {
            &.update-button {
              background: $accent-color;
              color: #fff;
              width: 150px;
              border: 0;
              box-shadow: none;
              margin: 0;
              border-radius: 4px;
              &:hover,
              &:focus,
              &:active {
                background: $accent-color;
                color: #fff;
                opacity: 0.9;
              }
            }
          }
        }
      }
      .margin-bottom-10 {
        margin-bottom: 10px;
      }
    }
  }

  &.header {
    &.comments-head {
      color: #343c49;
      font-size: 18px;
      margin: 25px 0 20px 0;
      &.mt-25 {
        margin-top: 25px;
      }
    }
  }
}

.update-button-wrapper {
  display: flex;
  justify-content: flex-end;
  &.my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &.mt-20 {
    margin-top: 20px;
  }
  &.mt-34 {
    margin-top: 34px;
  }
}

.move-date {
  input[type="text"] {
    &.form-control {
      width: 100%;
      border: 1px solid rgba(34, 36, 38, 0.15);
      box-shadow: none;
      margin: 0;
    }
  }
}

.comments-textarea {
  height: 80px;
  max-height: 500px;
  border-radius: 5px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 20px 60px 20px 20px;
  font-size: 16px;
  resize: none;
  &:focus {
    &:not([readonly]) {
      border: 1px solid #85b7d9;
      box-shadow: none;
      outline: 0;
    }
  }
}

.ui {
  &.feed {
    &.history-feed {
      max-height: 500px;
      overflow-y: auto;
      background: $primary-color-light;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 5px;
      margin-top: 24px;
      margin-bottom: 40px;
      padding: 20px 0 20px 0;
      .event {
        // padding: 12px 15px 10px 10px;
        padding: 22px 15px 15px 23px;
        border-bottom: 1px solid rgba(34, 36, 38, 0.15);
        position: relative;
        @media (max-width: 767px) {
          padding-bottom: 40px;
        }
        .label {
          position: absolute;
          right: 58px;
          top: 50%;
          margin-top: -26px;
          cursor: pointer;
          @media (max-width: 767px) {
            top: auto;
            padding: 0;
            bottom: 10px;
            right: 55px;
          }
          .pencil {
            font-size: 18px;
            &:hover {
              opacity: 0.8;
            }
            @media (max-width: 767px) {
              font-size: 16px;
            }
          }
        }
        &:last-child {
          border-bottom: 0;
        }
        .content {
          .summary {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

// add lead
.add-lead-modal {
  background: red !important;
}
.add-lead-modal,
.modal-radius {
  &.ui {
    &.modal,
    &.modal > :first-child:not(.icon):not(.dimmer) {
      border-radius: 0;
    }
  }
  &::after {
    display: none;
  }
  .ui {
    &.form {
      textarea {
        height: 60px;
        &.height-90 {
          height: 90px;
        }
      }
    }
  }
}

.ui {
  &.form {
    textarea {
      resize: none;
    }
  }
}

.form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .form-row-col {
    width: calc(50% - 10px);
  }
}

.form-submit-leads,
.schedule-tour-form {
  &.ui.form .inline.field > label,
  &.ui.form .field > label {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    margin-bottom: 5px;
  }
  .margin-top-20 {
    margin-top: 17px !important;
  }
}

.delete-icon {
  background-image: url(/assets/img/delete.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 18px;
  height: 18px;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -18px;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 767px) {
    top: auto;
    bottom: 13px;
    width: 17px;
    height: 17px;
  }
}

.leads-card {
  &.ui.cards > .card > .content > .header:not(.ui),
  &.ui.card > .content > .header:not(.ui) {
    font-weight: 500;
  }
}

.top-container-fluid {
  position: relative !important;
  min-height: 100vh !important;
  & > .row {
    min-height: 100vh !important;
    @include mobile-tablet {
      // margin-right:0px !important;
      // margin-left:0px !important;
      // width:100% !important;
    }
  }
}

.lead-table-list {
  thead {
    tr {
      &:first-child {
        display: none;
      }
    }
  }
}

.ui.search.selection.dropdown > input.search {
  box-sizing: border-box;
}

.height-40 {
  input {
    &[type="text"],
    &[type="number"] {
      height: 40px;
      box-sizing: border-box;
    }
  }
}
.height-38 {
  input {
    &[type="text"],
    &[type="number"] {
      height: 38px;
      box-sizing: border-box;
    }
  }
}

.add-lead-requirements-preferences {
  background: $primary-color-light;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  .move-date {
    input {
      &[type="text"] {
        height: 40px;
        box-sizing: border-box;
      }
    }
  }
}

.ui {
  &.modal {
    &.modal-wid-700 {
      &:not(.fullscreen) {
        @media (min-width: 1200px) {
          width: 740px;
        }
        @media (min-width: 992px) {
          width: 720px;
        }
      }
    }
  }
}

.autocomplete-dropdown-container {
  border-radius: 6px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  max-width: 100%;
  position: absolute;
  z-index: 10;
}

.ui {
  &.header {
    &.from-text,
    &.to-text {
      color: rgba(0, 0, 0, 0.5);
      font-size: 16px;
    }
    &.from-text {
      margin-top: 6px;
      margin-bottom: 0;
    }
    &.to-text {
      margin-top: 20px;
    }
  }
}

.ui[class*="equal width"] {
  &.grid {
    & > .column.edit-contact-info {
      &:not(.row) {
        display: flex;
        flex-direction: row;
      }
    }
  }
}

.schedule-date {
  & > div {
    & > div {
      margin: 0;
      padding: 0;
      border: 0;
    }
  }
  table {
    border-top: 0;
    thead {
      tr {
        &:first-child {
          th {
            &:hover {
              background: #343c49;
            }
          }
        }
      }
    }
  }
}

.select-time-buttons {
  .ui {
    &.basic {
      &.buttons {
        border-radius: 0;
        white-space: nowrap;
        &:not(:last-child) {
          border-right: 0;
        }
        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        .ui {
          &.button {
            padding: 16.5px 7px;
          }
        }
      }
    }
  }
}

.full-wid-input {
  width: 100%;
}

.breadcrumb {
  &.custom-breadcrumb {
    background: none;
    padding-left: 0;
    .divider {
      padding: 5px;
    }
  }
}

.ui {
  &.button {
    &.dark-button {
      background: $primary-color;
      color: $primary-color-light;
      &:hover,
      &:focus,
      &:active {
        background-color: $primary-color;
        color: $primary-color-light;
      }
    }
  }
}

.text-center {
  text-align: center;
}

.ui {
  &.inline {
    &.loader {
      &.custom-loader {
        position: absolute;
        top: 100%;
        left: 50%;
        height: 100%;
        width: 100%;
        transform: translate(-50%, -50%);
        margin-top: 25px;
      }
    }
  }
}

.ui.modal {
  > .header.modal-header-bg {
    background: #343c49;
  }
}
.modal{
  overflow: unset !important;
}

.ui.header.modal-heading-custom {
  &:not(h1) {
    &:not(h2) {
      &:not(h3) {
        &:not(h4) {
          &:not(h5) {
            &:not(h6) {
              color: #fff;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

.modal-ui {
  &.overflow-y-visible {
    overflow-y: visible;
  }
}

.ui {
  &.positive {
    &.button {
      &.modal-close-button {
        background: #343c49;
        &:hover,
        &:focus,
        &:active {
          background: #343c49;
        }
      }
      &.modal-save-button {
        background-image: linear-gradient(
          96deg,
          #3b1c5a,
          #374db1 163%
        ) !important;
        &:hover,
        &:focus,
        &:active {
          background: linear-gradient(96deg, #3b1c5a, #374db1 163%) !important;
        }
      }
    }
  }
}

.ui {
  &.form {
    .field {
      &.mt-30 {
        margin-top: 30px;
      }
    }
    textarea {
      &.create-lead-textarea {
        resize: none;
      }
    }
  }
  &.header {
    &.add-lead-requirements-heading {
      &:first-child {
        margin: 4px 0 10px 0;
      }
    }
  }
  &.grid {
    > .column.custom-padding {
      &:not(.row) {
        padding: 0 20px 0 0;
      }
    }
  }
  &.left {
    &.aligned {
      &.header {
        &.email-header-spacing {
          margin: 25px 0 20px 0;
        }
      }
    }
  }
}

.col-pd-left {
  padding-left: 0.5rem;
}
.col-pd-right {
  padding-right: 0.5rem;
}

.send-icon {
  width: 33px;
  height: 33px;
  display: inline-block;
  background: url("/assets/icons/send.svg") no-repeat;
  background-size: 100%;
}

.app-content {
  .ui {
    &.loader {
      z-index: 100;
    }
  }
}

#root {
  .Toastify__toast--success {
    background: $accent-color;
  }
  .Toastify__toast--error {
    background: #f44336;
  }
}

// for table sorting

table th .table-sorting::before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
table th .table-sorting.sort-desc::before {
  border-top: 5px solid $primary-color-light;
}
table th .table-sorting.sort-asc::before {
  border-bottom: 5px solid $primary-color-light;
}
.table-container tr th .table-sorting::before {
  top: 50%;
  margin-top: -2.5px;
}
.table-container.application-table tr th:nth-child(4),
.table-container.application-table tr th:nth-child(5) {
  min-width: 110px;
}

.custom-loader-dashboard {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50px 0px 0px -50px;
}

.ui.feed > .event > .label.text-danger .icon {
  color: inherit;
  cursor: pointer;
}

#root {
  position: relative;
}

.has-error {
  input {
    border-color: #db2828 !important;
  }
}

.ui.form textarea.invite-form-textarea {
  resize: none;
  height: 75px;
}

.form-control:disabled,
.form-control[readonly],
.form-control[readonly]:focus {
  background-color: #fff !important;
}

.create-lease-modal {
  max-width: 650px !important;
}

.ui.form .field.disabled > label,
.ui.form .fields.disabled > label,
.ui.form .disabled.fields .field,
.ui.form .disabled.field,
.ui.form .field :disabled,
.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: 1 !important;
}

.ui.form .field.disabled :disabled {
  pointer-events: initial !important;
  cursor: not-allowed;
}

.add-star {
  &::after {
    margin: -0.2em 0 0 0.2em;
    content: "*";
    color: #db2828;
    display: inline-block;
    vertical-align: top;
  }
}

.blue-border-textarea {
  border-color: #4c158a !important;
}

.leftnav.ui.menu.fixed.collapsed + .right-column .app-content {
  margin-left: 80px;
  width: calc(100% - 80px);
  // -webkit-transition: width 0.5s;
  // transition: width 0.5s;
  // -webkit-transition: margin 0.5s;
  // transition: margin 0.5s;
}

.mt-60 {
  margin-top: 60px !important;
}

.maintenance-table-list {
  tbody {
    td {
      &:first-child {
        word-break: break-all;
      }
    }
  }
}

.mxw-150 {
  max-width: 150px;
}
