@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

// @import "../../SiteShared.scss";

// .topnav.ui.menu.fixed {
//   height: $topnav-height;
//   background-color: $topnav-background-color;
//   margin-left: $leftnav-width-expanded;

//   //Adjust top navbar size and layout for non-phone screen sizes
//   @include not-phone{
//     width: calc(100% - #{$leftnav-width-expanded});
//     transition: width 0.5s ease-in-out 0.2s;
//     transition: margin 0.5s;

//     &.expanded {
//         margin-left: $leftnav-width-collapsed;
//         width: calc(100% - #{$leftnav-width-collapsed});
//         transition: width 0.5s;
//         transition: margin 0.5s;
//     }

//     .logo-small {
//       display: none;
//     }
//   }

//   //Adjust top navbar size and layout for phone screen size
//   @include for-phone {
//     margin-left: 0px;
//     width: 100%;

//     .logo-small {
//       height: 40px;
//       margin-top: 12px;
//       padding-left: 5px;
//       padding-right: 10px;
//     }
//   }

//   // Top navbar items (menu icon, notification icon, etc.)
//   .item {
//     padding-left: 0.9em;
//     padding-right: 0.9em;

//     i.icon {
//       // color: $topnav-font-color;
//     }

//     .large-icon {
//       font-size: 1.25em;
//     }
//   }

//   // Top navbar search
//   .leasera-search {
//     .ui.input {
//       input {
//         background-color: rgba(255,255,255,0.15);
//         color: $topnav-font-color;
//         border: none;

//         &:hover{
//           border-color: rgba(34, 36, 38, 0.15);
//           background-color: rgba(255,255,255,0.25);
//           color: $topnav-font-color-hover;
//           &::placeholder {
//             color: $topnav-font-color-hover;
//           }
//         }
//       }

//       input::placeholder {
//         color: $topnav-font-color;
//         @include for-phone {
//           color: $menu-font-color-primary;
//         }
//       }

//       i.icon {
//         color: $topnav-font-color;
//       }
//     }

//     .search-inline {
//       @include for-phone {
//         display: none;
//       }
//     }

//     .search-dropdown {
//       .ui.input {
//         width: 100%;
//       }

//       input {
//         width: 100%;
//         padding-top: 5px;
//         padding-bottom: 5px;
//         box-sizing: border-box;
//         height: 40px;
//         line-height: 1em;
//       }

//       //menu item text
//       span {
//         color: $menu-font-color-primary;
//       }

//       //menu item icon
//       i.icon {
//         color: $menu-font-color-primary;
//       }
//     }
//     .top-drawer {
//       display: none;
//     }
//   }

//   .property-selector {
//     .property-dropdown {
//       background-color: rgba(255,255,255,0.15);
//       color: $topnav-font-color;
//       border: none;
//       border-radius: 0.28571429rem;
//       font-size: 1em;
//       text-align: left;
//       height: 45px;
//       padding-left: 0.9em;
//       padding-right: 0.9em;

//       input {
//         font-size: 1em;
//         line-height: 26px;
//         color: $topnav-font-color;
//         height: 45px;
//       }

//       input::placeholder {
//         color: $topnav-font-color;
//       }

//       .text, .icon {
//         font-weight: 500;
//       }

//       .icon {
//         background-color: rgba(255,255,255,0.00);
//         color: rgba(232, 238, 248, 0.65);
//         font-size: 1.25em;
//         margin-left: 15px;
//         line-height: 45px;
//       }

//       &:hover{
//         border-color: rgba(34, 36, 38, 0.15);
//         background-color: rgba(255,255,255,0.25);
//         color: $topnav-font-color-hover;
//         border: none;

//         .icon {
//           color: $topnav-font-color-hover;
//         }

//         input::placeholder {
//           color: $topnav-font-color-hover;
//         }
//       }

//       &.visible {
//         .text {
//           color: $topnav-font-color;

//         }
//       }

//       .menu {
//         background-color: $menu-background-color-dropdown;
//         width: 100%;
//         max-height: 20em;

//         .text, .message {
//           color: $menu-font-color-primary;
//         }
//       }

//       &.property-selector-mobile {
//           width: 100%;

//           div.text {
//             line-height: 45px;
//             color: $menu-font-color-primary;
//           }

//           i.icon {
//             display: none;
//           }
//       }
//     }

//     @include for-phone {
//       padding: 0px;
//     }
//   }

//   // Topnav bar notification icon label count
//   .notif-count.ui.floating.label {
//     top: .9em;
//     left: 1.7em;
//     right: unset;
//   }
.feeds {
  color: red !important;
  margin: 0px;
  padding-top: 10px;
  padding-left: 10px;
}
//   .notifCount {
//     margin-top:15px;
//     margin-right:20px;
//   }

//   // Topnav bar dropdown user menu
//   .user-menu {
//     //font on topnav of user menu
//     i.icon {
//       // color: $topnav-font-color;
//       // font-size: 1.25em;
//     }

//     //icon on topnav of user menu
//     i.dropdown.icon {
//       display: none;
//     }

//     //text on topnav of user menu
//     span {
//       // color: $topnav-font-color;
//       // font-size: 1.15em;
//     }

//     // Hide name on smaller screen
//     .name {
//       display: none;
//     }

//     .dropdown-top-menu-user-info {
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       padding: 0 1.14285714em;
//       background: #4C158A;
//       border-left: 1px solid #fff;
//       margin-bottom: 0px;
//     }
//     .dropdown-divider-new {
//       display: block;
//       height: 1px;
//       background: #000;
//       margin: 15px 1.14285714em;
//     }

//     //the menu dropdown
//     &.dropdown.item .menu {
//       display: none;
//       width: 370px;
//       top: 0;
//       margin: 0;
//       background: #fff;
//       border: 0;
//       box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.52) !important;

//       //menu item text
//       span {
//         color: $menu-font-color-primary;
//       }

//       .item {
//         padding-top: 8px !important;
//         padding-bottom: 8px !important;
//         min-height: initial;
//       }

//       //menu item icon
//       i.icon {
//         // color: $menu-font-color-primary;
//       }
//     }

//   }
// }

// .phone-only {
//   @include not-phone {
//     display: none !important;
//   }
// }

// .phone-hide {
//   @include for-phone {
//     display: none !important;
//   }
// }

// .nav-icon {
//   color: $topnav-font-color;
//   height: 100%;

//   i.icon {
//     font-size: 1.25em;
//   }
// }

// .top-drawer {
//   .td-trigger.item {
//       color: $topnav-font-color;
//       height: 100%;
//       font-size: 1.25em;
//   }

//   .td-close {
//     text-align: center;
//     margin-top: 13px;
//     font-size: 0.85em;
//   }

//   .td-drawer {
//     visibility: hidden;
//     background-color: $menu-background-color-dropdown;
//     position: fixed;
//     z-index: 999;
//     left: 0px;
//     width: calc(100vw);
//     height: 0px;
//     transition: 0.5s ease-in-out;
//     padding: 5px;
//     top: $topnav-height;

//     * {
//       transition: 0.25s ease-in-out;
//       transition-delay: 0s;
//       visibility: hidden;
//     }

//     &.td-visible {
//       visibility: visible;
//       height: 90px;
//       transition: 0.5s ease-in-out;

//       * {
//         transition: 0.25s ease-in-out;
//         transition-delay: 0.4s;
//         visibility: visible;
//       }
//     }
//   }

//   .td-backdrop {
//     visibility: hidden;
//     transition: 0.5s ease-in-out;
//     background-color: rgba(255,255,225,0);
//     position: fixed;
//     top: $topnav-height;
//     left: 0px;
//     right: 0px;
//     bottom: 0px;

//     &.td-visible {
//       visibility: visible;
//       background-color: $backdrop-background-color;
//       z-index: 1;
//     }
//   }

// }

// .top-nav-item {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   margin: 0 8px;
//   align-items: center;
//   height: 64px;
//   cursor: pointer;
//   padding-top: 1px;
//   line-height: 1;
// }

// .top-nav-icon-wrapper {
//   background: #fff;
//   border-radius: 50%;
//   width: 30px;
//   height: 30px;
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   i {
//     &.icon {
//       margin: 0;
//     }
//     &.home {
//       &.icon {
//         font-size: 20px;
//       }
//     }
//     &.search {
//       &.icon {
//         font-size: 16px;
//       }
//     }
//     &.user {
//       &.icon {
//         font-size: 18px;
//       }
//     }
//   }
// }

// .top-nav-text {
//   color: #fff;
//   font-size: 8px;
//   font-family: 'Roboto', sans-serif;
//   font-weight: 400;
//   margin-top: 5px;
// }

// .top-nav-item-point {
//   display: flex;
//   align-items: center;
//   border-right: 1px solid #fff;
//   padding-right: 20px;
// }
// .points-info-wrap {
//   color: #fff;
//   text-align: center;
//   line-height: 1;
//   font-size: 20px;
//   font-weight: 500;
//   margin-left: 6px;
//   .points {
//     font-size:25px;
//   }
//   span {
//     font-size: 5px;
//     font-weight: normal;
//     display: block;
//     margin-top: 3px;
//   }
// }

// .topnav.ui.menu.fixed .header-right-menu .item {
//   padding: 0;
// }

// .home-icon {
//   width: 23px;
//   height: 23px;
//   display: inline-block;
//   background: url('/assets/img/dashboard/home-icon.png') no-repeat;
//   background-size: 100%;
// }
// .explore-icon {
//   width: 23px;
//   height: 23px;
//   display: inline-block;
//   background: url('/assets/img/dashboard/explore-icon.png') no-repeat;
//   background-size: 100%;
// }
// .points-icon {
//   width: 23px;
//   height: 23px;
//   display: inline-block;
//   background: url('/assets/img/dashboard/points-icon.png') no-repeat;
//   background-size: 100%;
// }
// .favorites-icon {
//   width: 23px;
//   height: 23px;
//   display: inline-block;
//   background: url('/assets/img/dashboard/favorites-icon.png') no-repeat;
//   background-size: 100%;
// }
// .message-icon {
//   width: 23px;
//   height: 23px;
//   display: inline-block;
//   background: url('/assets/img/dashboard/message-icon.png') no-repeat;
//   background-size: 100%;
// }
// .user-icon {
//   width: 23px;
//   height: 23px;
//   display: inline-block;
//   background: url('/assets/img/dashboard/user-icon.png') no-repeat;
//   background-size: 100%;
//   margin-top: 3px;
// }

// .ml-15 {
//   margin-left: 15px;
// }

@import "../../SiteShared.scss";

.topnav.ui.menu.fixed {
  height: 85px;
  background-color: #f5f5f5;
  margin-left: $leftnav-width-expanded;
  border-radius: 6px 6px 0px 0px !important;
  box-shadow: unset;
  border: 0px;
  position: absolute;
  padding: 25px 50px;
  //Adjust top navbar size and layout for non-phone screen sizes
  @include not-phone {
    @media (min-width: 767px) {
      width: calc(100% - #{$leftnav-width-expanded + 20px});
      right: 20px;
      .logo-small {
        display: none;
      }
    }
    // transition: width 0.5s ease-in-out 0.2s;
    // transition: margin 0.5s;
    top: 20px;
    left: auto;
    &.expanded {
      margin-left: 80px;
      width: calc(100% - #{80px});
      // transition: width 0.5s;
      // transition: margin 0.5s;
    }
  }

  //Adjust top navbar size and layout for phone screen size
  @include mobile-tablet {
    margin-left: 0 !important;
    background-image: linear-gradient(107deg, #3b1c5a, #374db1 162%);
    height: auto;
    align-items: center;
    border-radius: 0 !important;
    padding: 20px 0;
    // flex-direction: column;

    .fixed-menu-top {
      align-items: center;
      padding: 20px 0;
    }
    .fixed-menu-bottom {
      display: flex;
      width: 100%;
      padding: 0 20px 18px;
      .fixed-menu-left {
        width: 100%;
        margin-right: 8px;
        .leasera-search {
          display: block;
          width: 100%;
          margin-left: 0;
          .search-inline {
            display: block;
            input {
              width: auto;
              border-radius: 4px;
              background-color: #ffffff14;
              color: #cecece;
              ::-webkit-input-placeholder {
                /* Edge */
                color: #cecece;
              }
              :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #cecece;
              }
              ::placeholder {
                color: #cecece;
              }
            }
          }
          .top-drawer {
            display: none;
          }
        }
      }
      .fixed-menu-right {
        width: 100%;
        margin-left: 8px;
      }
      .property-selector {
        display: unset !important;
        .property-dropdown {
          display: block !important;
          background-color: #ffffff14;
          color: #cecece;
          border-radius: 4px;
          width: 100%;
          input {
            width: 85%;
          }
          .dropdown.icon::before {
            color: #cecece;
          }
          .menu {
            left: unset;
            right: 0;
          }
        }
        .top-drawer {
          display: none !important;
        }
      }
    }

    // Removable code
    // display: none;

    .logo-small {
      height: 25px;
      // margin-top: 12px;
      padding-left: 20px;
      padding-right: 10px;
    }
    .leasera-search,
    .top-nav-item-point,
    .property-selector {
      display: none;
    }
    .right.menu {
      padding-right: 20px;
      .notification-btn {
        background-color: #ffffff0d;
        margin-right: 0;
        margin-bottom: 0;
        svg {
          path + path {
            fill: #fff;
          }
        }
      }
      .user-menu {
        background-color: #ffffff0d;
        border-radius: 3px !important;
        margin-left: 14px;
        i.icon {
          color: #fff;
          opacity: 0.7;
        }
        .top-nav-item {
          .bars.icon {
            height: 23px;
            &::before {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  // Top navbar items (menu icon, notification icon, etc.)
  & > .item {
    background-color: #e6e6e6;
    width: 35px;
    height: 35px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    i.icon {
      color: rgba(94, 98, 103, 0.7);
      font-size: 12px;
      margin: 0px;
    }

    // .large-icon {
    //   font-size: 1.25em;
    // }
  }

  // Top navbar search
  .leasera-search {
    margin-left: 10px;
    width: 250px;
    .search-inline {
      padding: 0px;
      height: 100%;
      margin-top: 0px !important;
    }
    .ui.input {
      height: 35px;
      margin: 0px;
      input {
        background-color: #e6e6e6;
        border: none;
        padding: 0px 10px;
        color: #171a1d;
        height: 100%;
        width: 100%;
        border-radius: 6px;
      }

      input::placeholder {
        color: #171a1d;
        @include for-phone {
          color: $menu-font-color-primary;
        }
      }

      i.icon {
        color: $topnav-font-color;
      }
    }

    .search-inline {
      margin-top: 3px;
      @include for-phone {
        display: none;
      }
    }

    .search-dropdown {
      .ui.input {
        width: 100%;
      }

      input {
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        box-sizing: border-box;
        height: 40px;
        line-height: 1em;
      }

      //menu item text
      span {
        color: $menu-font-color-primary;
      }

      //menu item icon
      i.icon {
        color: $menu-font-color-primary;
      }
    }
  }

  .property-selector {
    .property-dropdown {
      background-color: #e6e6e6;
      color: #171a1d;
      border: none;
      border-radius: 0.28571429rem;
      font-size: 1em;
      text-align: left;
      height: 35px;
      padding-left: 0.9em;
      padding-right: 0.9em;
      width: 160px;
      border-radius: 6px;
      input {
        font-size: 14px;
        line-height: 26px;
        color: #171a1d;
        height: 35px;
      }

      input::placeholder {
        color: $topnav-font-color;
      }
      .text {
        top: 10px;
        font-size: 13px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100px;
      }
      .dropdown.icon {
        position: absolute;
        right: 15px;
        top: 10px;
        &::before {
          content: "\f107" !important;
          font-family: "FontAwesome";
          color: rgba(0, 0, 0, 0.5);
        }
      }
      &.visible {
        .text {
          color: #171a1d;
        }
      }

      .menu {
        background-color: $menu-background-color-dropdown;
        width: 100%;
        max-height: 20em;

        .text,
        .message {
          color: $menu-font-color-primary;
        }
      }

      &.property-selector-mobile {
        width: 100%;

        div.text {
          line-height: 45px;
          color: $menu-font-color-primary;
        }

        i.icon {
          display: none;
        }
      }
    }

    @include for-phone {
      padding: 0px;
    }
  }

  // Topnav bar notification icon label count
  .notif-count.ui.floating.label {
    top: -9px;
    min-width: 0px;
    min-height: 0px;
    width: 15px;
    height: 15px;
    left: 34px;
    line-height: 5px;
  }

  // Topnav bar dropdown user menu
  .user-menu {
    width: 35px;
    height: 35px;
    padding: 0px;
    background-color: #e6e6e6;
    border-radius: 6px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 14px;
    //font on topnav of user menu
    // background-color: red;
    .top-nav-item {
      i.icon {
        color: #5e6267;
        margin: 0px;
        &::before {
          content: "\f007";
          font-size: 13px;
        }
        &.alarm {
          &::before {
            content: "\f0f3";
          }
        }
        &.bars {
          &::before {
            content: "\2630 ";
          }
        }
      }
    }

    //icon on topnav of user menu
    i.dropdown.icon {
      display: none;
    }

    //text on topnav of user menu
    span {
      color: $topnav-font-color;
      font-size: 1.15em;
    }

    // Hide name on smaller screen
    .name {
      display: none;
    }

    //the menu dropdown
    &.dropdown.item .menu {
      display: none;
      // background-color: $menu-background-color-dropdown;
      width: 350px;
      padding: 20px 0 0;
      border-radius: 3px !important;
      box-shadow: 0 11px 14px -10px #e5eced !important;
      background-color: #ffffff;
      border: 0px;
      padding-top: 0px;
      top: 54px;
      //menu item text
      span {
        color: $menu-font-color-primary;
      }

      //menu item icon
      i.icon {
        color: $menu-font-color-primary;
      }
      &.notification-wrap {
        .ui.feed {
          margin-top: 0px;
          overflow-x: hidden;
        }
        .dropdown-top-menu-user-info {
          padding: 20px;
          border-bottom: 1px solid rgba(94, 98, 103, 0.1);
          p {
            font-size: 14px;
            font-weight: 500;
            color: #171a1d;
          }
        }
        .detail-txt {
          display: flex;
          padding: 20px;
          .event {
            margin-left: 10px;
            width: calc(100% - 40px);
            .summary {
              font-size: 13px;
              font-weight: normal;
              line-height: 22px;
              color: #171a1d;
              white-space: initial;
            }
            .meta {
              font-size: 12px;
              font-weight: normal;
              color: #5e6267;
            }
          }
        }
        .ui.divider {
          margin: 0px;
        }
        .icon-block {
          width: 35px;
          height: 35px;
          padding: 5px;
          background-color: rgba(59, 28, 90, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          color: #3b1c5a !important;
          i {
            color: #3b1c5a;
            margin: 0px;
          }
        }
      }
      &.profile-list {
        .item {
          height: 45px;
          display: flex !important;
          align-items: center;
          background-repeat: no-repeat !important;
          background-position: center left 15px !important;
          padding: 10px 45px !important;
          span {
            font-size: 14px !important;
            font-weight: normal;
            color: #171a1d;
          }
          &.application-txt {
            background-image: url("/assets/img/icons-application.svg") !important;
          }
          &.friends-txt {
            background-image: url("/assets/img/icons-friends.svg") !important;
          }
          &.signout-txt {
            background-image: url("/assets/img/icons-sign-out.svg") !important;
          }
        }
        .dropdown-top-menu-user-info {
          display: flex;
          padding: 20px;
          .user-image {
            width: 80px;
            height: 80px;
            img {
              width: 100%;
            }
          }
          .user-details {
            margin-left: 15px;
            .user-name {
              font-size: 15px;
              font-weight: 600;
              color: #3b1c5a;
              font-family: "Be Vietnam", sans-serif !important;
            }
            .email-id {
              font-size: 13px;
              font-weight: 500;
              color: #5e6267;
              font-family: "Be Vietnam", sans-serif !important;
            }
            .profile-rw {
              opacity: 1;
              border-radius: 3px;
              background: rgba(59, 28, 90, 0.1);
              width: 195px;
              height: 30px;
              padding: 0px;
              margin-top: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              .text {
                font-size: 12px;
                color: #3b1c5a;
                font-weight: 800;
                text-transform: uppercase;
                font-family: "Be Vietnam", sans-serif !important;
              }
            }
          }
        }
      }
    }
  }
}
.ui.menu.fixed.topnav .item.notification-col {
  width: 35px;
  height: 35px;
  padding: 0px !important;
  background-color: #e6e6e6 !important;
  border-radius: 6px !important;
  margin-left: 10px;
  justify-content: center;
}
.phone-only {
  @include not-phone {
    display: none !important;
  }
}

.phone-hide {
  @include for-phone {
    display: none !important;
  }
}

.nav-icon {
  color: $topnav-font-color;
  height: 100%;

  i.icon {
    font-size: 1.25em;
  }
}

.top-drawer {
  .td-trigger.item {
    color: $topnav-font-color;
    height: 100%;
    font-size: 1.25em;
  }

  .td-close {
    text-align: center;
    margin-top: 13px;
    font-size: 0.85em;
  }

  .td-drawer {
    visibility: hidden;
    background-color: $menu-background-color-dropdown;
    position: fixed;
    z-index: 999;
    left: 0px;
    width: calc(100vw);
    height: 0px;
    transition: 0.5s ease-in-out;
    padding: 5px;
    top: $topnav-height;

    * {
      transition: 0.25s ease-in-out;
      transition-delay: 0s;
      visibility: hidden;
    }

    &.td-visible {
      visibility: visible;
      height: 90px;
      transition: 0.5s ease-in-out;

      * {
        transition: 0.25s ease-in-out;
        transition-delay: 0.4s;
        visibility: visible;
      }
    }
  }

  .td-backdrop {
    visibility: hidden;
    transition: 0.5s ease-in-out;
    background-color: rgba(255, 255, 225, 0);
    position: fixed;
    top: $topnav-height;
    left: 0px;
    right: 0px;
    bottom: 0px;

    &.td-visible {
      visibility: visible;
      background-color: $backdrop-background-color;
      z-index: 1;
    }
  }
}
.top-nav-item-point {
  background-color: rgba(59, 28, 90, 0.05);
  display: flex;
  align-items: center;
  width: 138px;
  height: 35px;
  justify-content: center;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  color: #3b1c5a;
  a {
    &:hover {
      color: unset;
    }
  }
  .points {
    padding-right: 5px;
  }
}

.toggle-alerts {
  border-radius: 3px;
  border: none;
  background-image: linear-gradient(102deg, #3b1c5a, #374db1 163%);
  color: white;
  position: absolute;
  right: 14px;
  top: 20px;
  font-weight: bold;
  font-size: 13px;
}

// Mobile menu CSS
.mobile-menu-modal {
  position: absolute;
  top: 75px;
  width: 100%;
  background-color: #fff;
  z-index: 9999;
  flex-direction: column;
  justify-content: space-between;
  // display: none;
  @include mobile-tablet {
    // display: flex;
  }
  .mobile-menu-list {
    .mobile-menu-list-item {
      a {
        display: flex;
        padding: 10px 25px;
        opacity: 0.7;
        span {
          font-size: 15px;
          color: #171a1d;
          padding-left: 15px;
          line-height: 31px;
          padding-bottom: 4px;
        }
      }
      &.active {
        a {
          opacity: 1;
          background-color: #3b1c5a0d;
        }
      }
    }
  }
  .mobile-menu-button {
    padding: 25px 20px;
    .btn {
      justify-content: center;
      background-color: #171a1d0d !important;
      color: #171a1d !important;
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
