@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/_variables";

.notification-wrapper {
  .header-rw {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
      display: block;
    }

    .header {
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 0px;
      text-align: left !important;
      color: #171a1d;
      font-family: "Be Vietnam", sans-serif !important;
    }
    p {
      font-size: 13px;
      font-weight: normal;
      color: #5e6267;
    }
    .right-top-head {
      input {
        border-radius: 3px;
        box-shadow: 0 11px 14px -10px #e5eced;
        background-color: #ffffff;
        border: 0px;
        height: 45px;
        margin-right: 20px;
        font-size: 13px;
        font-weight: normal;
        width: 230px;
        box-sizing: border-box;
        color: rgba(23, 26, 29, 0.7);
      }
      button {
        &.ui.button {
          background-image: linear-gradient(100deg, #3b1c5a, #374db1 163%);
          height: 45px;
          color: #fff;
          width: 250px;
          padding: 0px !important;
          box-shadow: unset;
          border-radius: 3px !important;
        }
      }
    }
  }
}

.notification-bell-navbar {
  position: relative;

  a.notification-anchor > i.batch-icon-bell {
    color: $qp-color-primary !important;
  }

  // Removes the janky re-sizing when with notifications vs none
  a {
    padding: 0px !important;
    margin: 0 !important;
  }

  ul .dropdown-menu .dropdown-menu-right .dropdown-menu-md {
    a.notification-anchor {
      margin: 0 0 0 0 !important;
      padding: 0 0 0 0 !important;
    }
  }

  ul.dropdown-menu {
    padding: 0;
    max-height: 540px;
    overflow-y: scroll;
  }

  // Removes the janky re-sizing when with notifications vs none
  li {
    padding: 15px !important;
    margin: 0 !important;
  }

  li.nav-item,
  .dropdown {
    position: relative;
    padding: 0;

    &:hover {
      span#navbar-notification-misc > i.batch-icon-bell {
        color: inherit !important;
      }
    }
    span.nav-link:hover {
      .batch-icon-bell {
        color: inherit !important;
      }
    }
  }

  i.batch-icon-bell:not(.not-allowed) {
    color: inherit;
  }

  a.notification-anchor {
    i.batch-icon,
    i.batch-icon-bell {
      margin: 0 10px 0 0;
    }
  }

  span.notification-number {
    // position: absolute !important;
    transform: translate(8px, -8px);
    height: 25px !important;
    padding: 1px 0 !important;
    line-height: normal !important;
    display: block;
  }

  .media-body {
    position: relative;

    span.notification-time {
      font-style: italic !important;
    }

    button.mark-read-button {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      cursor: pointer;

      // Remove button default styles
      border: none;
      background: transparent;

      i.batch-icon-delete {
        font-size: 15px;

        &:hover {
          font-size: 18px;
          transform: translate(1px, 1px);
        }
      }
    }
  }

  span#navbar-notification-misc {
    padding: 0 5px;
  }

  button.mark-all-read-button {
    float: right;
    background: transparent;
    border: none;
    font-size: 0.75rem;
    font-style: italic;
    color: $qp-color-primary;

    &:hover {
      font-weight: bold;
    }
  }

  .media:hover {
    span.notification-time {
      color: white !important;
    }

    span.notification-text {
      font-weight: bold;
      color: $qp-color-secondary !important;
    }

    .batch-icon-bell {
      color: $qp-color-secondary !important;
      padding: 0 0 0 20px;
    }

    .batch-icon-delete {
      color: $qp-color-secondary !important;
    }
  }
}

.url:hover {
  color: #886ca8 !important;
}