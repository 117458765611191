@font-face {
  font-family: "Avenir-light", sans-serif;
  font-weight: 300;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Book.ttf);
}
@font-face {
  font-family: "Avenir-regular", sans-serif;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Roman.ttf);
}
@font-face {
  font-family: "Avenir-bold", sans-serif;
  font-weight: 600;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Medium.ttf);
}
@font-face {
  font-family: "Avenir-black", sans-serif;
  font-weight: 900;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Heavy.ttf);
}

@font-face {
  font-family: "BeVietnam", sans-serif;
  font-weight: 100;
  font-display: swap;
  font-style: normal;
  src: url(/assets/fonts/BeVietnam-Thin.ttf);
}
@font-face {
  font-family: "BeVietnam", sans-serif;
  font-weight: 100;
  font-display: swap;
  font-style: italic;
  src: url(/assets/fonts/BeVietnam-ThinItalic.ttf);
}
@font-face {
  font-family: "BeVietnam", sans-serif;
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  src: url(/assets/fonts/BeVietnam-Light.ttf);
}
@font-face {
  font-family: "BeVietnam", sans-serif;
  font-weight: 400;
  font-display: swap;
  font-weight: normal;
  font-style: normal;
  src: url(/assets/fonts/BeVietnam-Regular.ttf);
}
@font-face {
  font-family: "BeVietnam", sans-serif;
  font-weight: 400;
  font-display: swap;
  font-weight: normal;
  font-style: italic;
  src: url(/assets/fonts/BeVietnam-RegularItalic.ttf);
}
@font-face {
  font-family: "BeVietnam", sans-serif;
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src: url(/assets/fonts/BeVietnam-Medium.ttf);
}
@font-face {
  font-family: "BeVietnam", sans-serif;
  font-weight: 500;
  font-display: swap;
  font-style: italic;
  src: url(/assets/fonts/BeVietnam-MediumItalic.ttf);
}
@font-face {
  font-family: "BeVietnam", sans-serif;
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  src: url(/assets/fonts/BeVietnam-SemiBold.ttf);
}
@font-face {
  font-family: "BeVietnam", sans-serif;
  font-weight: 600;
  font-display: swap;
  font-style: italic;
  src: url(/assets/fonts/BeVietnam-SemiBoldItalic.ttf);
}
@font-face {
  font-family: "BeVietnam", sans-serif;
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  src: url(/assets/fonts/BeVietnam-Bold.ttf);
}
@font-face {
  font-family: "BeVietnam", sans-serif;
  font-weight: 800;
  font-display: swap;
  font-style: normal;
  src: url(/assets/fonts/BeVietnam-ExtraBold.ttf);
}
@font-face {
  font-family: "BeVietnam", sans-serif;
  font-weight: 800;
  font-display: swap;
  font-style: italic;
  src: url(/assets/fonts/BeVietnam-ExtraBoldItalic.ttf);
}
