@font-face {
  font-family: "Avenir-light", sans-serif;
  font-weight: 300;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Book.ttf);
}
@font-face {
  font-family: "Avenir-regular", sans-serif;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Roman.ttf);
}
@font-face {
  font-family: "Avenir-bold", sans-serif;
  font-weight: 600;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Medium.ttf);
}
@font-face {
  font-family: "Avenir-black", sans-serif;
  font-weight: 900;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Heavy.ttf);
}
/* 
	DO NOT DELETE
	THIS SCSS/CSS FILE IS USED BY SCSS FILES WITHING THE React/view AND React/layout folders

 */
/* QUILLPRO MIXINS */
/* font family*/
.semanticModal {
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
  overflow-y: auto;
}
.semanticModal-request-header {
  background: #4c158a !important;
}
.semanticModal-request-footer {
  background: #bfb4d0 !important;
}
.semanticModal-request-submit {
  background: #4c158a !important;
  color: #ffffff !important;
}
.semanticModal-request-close {
  background: #4c158a !important;
  color: #ffffff !important;
}

#unitFormInput,
#priorityFormInput,
#categoryFormInput {
  border-color: #4c158a !important;
}

@media only screen and (min-width: 1080px) {
  .header-rw-responsive {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .input-res {
    border-radius: 3px;
    box-shadow: 0 11px 14px -10px #e5eced;
    background-color: #ffffff;
    border: 0px;
    height: 45px;
    margin-right: 20px;
    font-size: 13px;
    font-weight: normal;
    width: 230px;
    box-sizing: border-box;
    color: rgba(23, 26, 29, 0.7);
  }

  .right-top-head-responsive {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 300px;
    height: 50px;
    right: 6%;
  }
}

@media only screen and (min-width: 380px) {
  .header-rw-responsive {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .left-top-head {
    margin-bottom: 30px;
  }

  .input-res {
    border-radius: 3px;
    box-shadow: 0 11px 14px -10px #e5eced;
    background-color: #ffffff;
    border: 0px;
    height: 45px;
    margin-right: 20px;
    font-size: 13px;
    font-weight: normal;
    width: 230px;
    box-sizing: border-box;
    color: rgba(23, 26, 29, 0.7);
  }
}
