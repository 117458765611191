@import "../../styles/_variables";
@import "../../SiteShared.scss";

.messages {
  font-family: "Be-Vietnam", sans-serif;

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  h6 {
    color: $gray-dark;
  }

  .search-input-wrapper {
    background-color: #fff;
    height: 45px;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    display: flex;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding-left: 15px;

    input.search-user {
      flex: 1;
      border: 0 !important;
      box-shadow: none !important;
      border-radius: 3px;
      color: $gray-dark;
      font-size: 13px;
      font-weight: 400;
      margin: 0;
      padding: 0 10px;
      line-height: 45px;
    }
  }

  .new-conversation-wrapper {
    display: flex;
    height: 45px;
    align-items: center;
    justify-content: center;

    button {
      margin: 0;
      height: 45px;
    }
  }
}

@include for-desktop {
  .messages {
    .new-conversation-wrapper {
      button {
        padding: 0 60px;
        margin-left: 15px;
      }
    }
  }
}

@include for-phone {
  .footer {
    display: none !important;
  }

  .messages {
    .new-conversation-wrapper {
      button {
        width: 45px;
        height: 45px;
        margin-left: 10px;

        span {
          font-size: 30px;
          font-weight: 300;
        }
      }
    }
  }
}
