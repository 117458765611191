@import "../../SiteShared.scss";

.main-calendar {
  font-size: 16px;
  height: 100%;
  @media (max-width: 768px) {
    padding: 0px;
    margin: 5px !important;
  }
  .calendar-header.ui.menu {
    color: $primary-color-light;
    background-color: $primary-color;

    .month-label {
      font-size: 1.5em;
      line-height: 40px;
    }

    .calendar-action.item {
      color: $primary-color-light;
    }
  }

  .calendar-body {
    border: 0px !important;

    &.ui.attached.segment {
      max-width: 100%;
    }

    .calendar-dow {
      background-color: #ffffff;
      color: #5e6267;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      border-bottom: 1px solid #f0f0f0;
    }
    .day {
      border: 0px;
      border-right: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      height: 120px;
      cursor: pointer;
      &:nth-child(7n + 1) {
        border-left: 1px solid #f0f0f0;
      }
      &:hover {
        background-color: unset;
      }
      .day-number {
        font-size: 14px;
        font-weight: normal;
        color: #5e6267;
        text-align: right;
      }

      &.different-month {
        opacity: 1;
        background-color: #f8f8f8;
        .day-number {
          color: rgba(94, 98, 103, 0.49);
        }
      }
      &.today {
        background: unset;
        .day-number {
          width: 29px;
          height: 29px;
          display: flex;
          margin-left: auto;
          background-color: #3ab292;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          color: #fff;
          font-weight: 600;
        }
      }
      .event-label {
        border-radius: 4px;
        background-color: #ededed;
        opacity: 1;
        padding: 7px 10px;
        text-align: left;
        font-size: 12px;
        font-weight: 500;
        margin-top: 5px;
      }
    }
  }

  .events-daily {
    .event-card.ui.card {
      width: 100%;
      text-align: left;
      background-color: $accent-color-light;
      font-size: 1em;

      .event-delete {
        float: right;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
} //End of calendar container

.days-title {
  color: $primary-color;
  width: 100%;
}

.day {
  min-height: 100px;
  border: $primary-color-light solid 1px;

  &:hover {
    background-color: $accent-color-light;
    .day-number {
      cursor: default;
    }
  }

  &.today {
    background: $accent-color-medium;
    .day-number {
      color: $primary-color;
    }
  }

  &.before {
    background-color: #f8f8f8;
    opacity: 0.25;
    .day-number {
      color: $primary-color-medium;
    }
  }

  &.different-month {
    opacity: 0.25;
    .day-number {
      color: $primary-color-medium;
    }
  }

  .day-number {
    font-size: 1.25em;
  }

  .event-label {
    opacity: 0.5;
    padding-top: 15px;
  }
}

.dashboard-cal {
  .main-calendar {
    max-height: 0px !important;
  }
  .day {
    min-height: 20px;
    // max-height:20px;
    border: $primary-color-light solid 1px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    &:hover {
      background-color: $accent-color-light;
      .day-number {
        cursor: default;
      }
    }
    &.chosen {
      border: $accent-color-medium solid 1px;
      background-color: $accent-color-light;
    }
    &.today {
      background: $accent-color-medium;
      .day-number {
        color: $primary-color;
      }
    }

    &.before {
      .day-number {
        color: $primary-color-medium;
      }
    }

    &.different-month {
      opacity: 0.25;
      .day-number {
        color: $primary-color-medium;
      }
    }

    .day-number {
      font-size: 1.25em;
    }

    .event-label {
      opacity: 0.5;
      padding-top: 0px !important;
    }
  }
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  .top-left {
    h5 {
      font-size: 20px !important;
      font-weight: 800 !important;
      color: #171a1d;
      text-align: left;
      margin-bottom: 0px;
    }
    p {
      font-size: 13px;
      font-weight: normal;
      color: #5e6267;
    }
  }
  .top-right {
    display: flex;
    align-items: center;
    .date-txt {
      border-radius: 3px;
      background-color: rgba(94, 98, 103, 0.05);
      width: 165px;
      height: 45px;
      font-size: 13px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .arrow-fnc {
      display: flex;
      border-radius: 3px;
      background-color: rgba(94, 98, 103, 0.05);
      margin-left: 10px;
      .calendar-action {
        border-right: 1px solid rgba(94, 98, 103, 0.1);
        &:last-child {
          border-right: 0px;
        }
        i {
          font-size: 12px;
          color: #5e6267;
        }
        &:before {
          background: unset;
        }
      }
    }
    .menu {
      border: 0px !important;
      background-color: unset;
    }
    button {
      border-radius: 3px;
      background-image: linear-gradient(106deg, #3b1c5a, #374db1 162%);
      min-width: 150px;
      height: 45px;
      border: 0px;
      font-size: 13px;
      font-weight: 800;
      text-transform: uppercase;
      box-sizing: border-box;
      padding: 0 10px;
      margin-left: 30px;
      cursor: pointer;
      &:focus {
        box-shadow: unset;
      }
    }
  }
}

// add schedule modal
.add-schedule-modal {
  .modal-content {
    border-radius: 6px;
    background-color: #f8f8f8;
    box-shadow: unset;
    .modal-header {
      background: unset;
      border: 0px;
      padding: 50px 50px 0px 50px;
      h5 {
        font-size: 22px !important;
        font-weight: 800 !important;
        color: #171a1d;
        font-family: "Be Vietnam", sans-serif !important;
        margin-bottom: 30px;
      }
      .close {
        padding: 0px;
        position: absolute;
        top: 30px;
        right: 35px;
      }
    }
    .modal-body {
      margin: 0px 50px;
      padding: 0px;
      .form-rw {
        display: flex;
        margin: 0px -10px;
        .form-group {
          margin: 0px 10px;
        }
      }
      .form-group {
        position: relative;
        label {
          font-size: 13px;
          font-weight: 500;
          position: absolute;
          top: 15px;
          left: 20px;
          font-family: "Be Vietnam", sans-serif !important;
          color: #5e6267;
        }
        input,
        textarea {
          border-radius: 3px;
          box-shadow: 0 11px 14px -10px #e5eced;
          border: 0px;
          height: 75px;
          padding: 15px 20px 0px;
          font-size: 14px;
          font-weight: 500;
          color: #171a1d;
          width: 100%;
          box-sizing: border-box;
        }
        textarea {
          height: 138px;
          resize: none;
          padding: 35px 20px 0px;
        }
      }
    }
    .modal-footer {
      background: unset;
      padding: 10px 50px 50px;
      border: 0px;
      button {
        height: 45px;
        background-image: linear-gradient(
          96deg,
          #3b1c5a,
          #374db1 163%
        ) !important;
        width: 100%;
        font-size: 13px;
        font-weight: 800;
        line-height: 45px;
        text-transform: uppercase;
      }
    }
  }
}
