@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/_variables";

.markets-grid-container {
  display: grid;
  width: 100%;
  gap: 1rem;
  grid-template: auto / repeat(auto-fill, minmax(300px, 1fr));
  margin-bottom: 2rem;
  padding: 0 1rem;

  & > * {
    backface-visibility: hidden;
    transition: all 0.3s !important;
  }

  & > *:hover {
    transform: translateY(-2px) scale(1.01);
  }
}
.reward-label {
  font-size: 25px;
  font-weight: 800;
  color: #3b1c5a;
  margin-bottom: 8px;
}
.gradient-markets {
  background: linear-gradient(135deg, #4c158a, #32dac3, #17a2b8, #007bff);
}

h5 {
  color: $primary-color;
}

blockquote {
  border: 0;
}

blockquote > p {
  padding: 0;
}

.padding-left {
  padding-left: 1.5em;
}

.table-markets {
  box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06) !important;
  transition: box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  border-radius: 0.4167rem;
}

.fixed_header {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100% !important;
}

.fixed_header tbody {
  /* body takes all the remaining available space */
  padding-left:0px !important;
  flex: 1 1 auto;
  display: block;
  overflow-y: overlay;
  span {
    padding-left:5px;
  }
}

.fixed_header tbody tr {
  flex: 0 0 auto;
  width: calc(100% - 0.1em);
}
.fixed_header {
  table thead,
  table tfoot,
  table tbody tr {
    display: table;
    table-layout: fixed;
  }
}

.fixed_header thead {
  /* head takes the height it requires, 
    and it's not scaled when table is resized */
  flex: 0 0 auto;
  width: calc(100% - 0.8em);
}
.fixed_header tfoot {
  /* head takes the height it requires, 
     and it's not scaled when table is resized */
  flex: 0 0 auto;
  width: calc(100% - 0.8em);
}

.fixed_header th,
.fixed_header td {
  padding: 5px;
  text-align: left;
  width: 100%;
}
.rowScroll {
  max-height: 500px;
  table {
    width: 100% !important;
    tr {
      td {
        //  background:red !important;
        width: 50%;
      }
      th {
        width: 50%;
      }
    }
  }
}

.markets-col {
  width: auto;
  p {
    color: $theme-text-color;
    font-size: 16px;
    line-height: 3rem;
    width: auto;
  }
  h2 {
    color: $black;
    font-size: 30px;
    margin: 0;
    line-height: 3rem;
  }
  span {
    width: 15px;
    height: 15px;
    display: inline-block;
    background: $primary-color;
    border-radius: 50%;
    text-align: center;
    line-height: 15px;
    color: $white;
    font-size: 10px;
    margin-left: 3px;
    position: relative;
    top: -7px;
  }
}

.popup-banner {
  color: linear-gradient(96deg, #3b1c5a, #374db1 163%) !important; 
  img {
    background-image: linear-gradient(96deg, #3b1c5a, #374db1 163%); 
  }
}

// market points css
.market-points-wrap {
  h3 {
    font-size: 20px;
    font-weight: 800 !important;
    margin-bottom: 0px;
    color: #171a1d;
  }
  h6 {
    font-size: 13px;
    font-weight: normal !important;
    color: #5e6267;
    margin-bottom: 50px;
  }

  .points-status {
    border-radius: 3px;
    background-image: linear-gradient(96deg, #3b1c5a, #374db1 163%);
    margin: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 30px;
    .left-data {
      display: flex;
      align-items: center;
      .congrats-text {
        margin-left: 16px;
        h4 {
          font-size: 20px;
          font-weight: bold !important;
          color: #fff;
          margin-bottom: 0px;
        }
        span {
          opacity: 0.7;
          color: #fff;
          font-size: 13px;
          font-weight: normal;
        }
      }
    }
    .right-data {
      button {
        border-radius: 3px;
        background-color: rgba(255, 255, 255, 0.1) !important;
        width: 150px;
        height: 35px;
        line-height: 35px;
        font-size: 13px;
        font-weight: 800;
        text-transform: uppercase;
      }
    }
  }
  .activity-status {
    border-radius: 3px;
    box-shadow: 0 11px 14px -10px #e5eced;
    background-color: #ffffff;
    margin-top: 50px;
    h2 {
      margin-bottom: 0px;
      padding: 15px 20px;
      font-size: 14px !important;
      font-weight: 500 !important;
    }
    .table.fixed_header {
      margin-top: 0px;
      border: 0px;
      thead {
        width: 100%;
        background-color: rgba(94, 98, 103, 0.1);
        tr {
          padding: 7px 20px;
          height: 30px;
          display: flex;
          text-align: left;
        }
        th {
          padding: 0px;
          font-size: 11px;
          font-weight: bold !important;
          height: 100%;
          background-color: unset !important;
          border: 0px !important;
          color: #5e6267;
          text-transform: uppercase;
          &:first-child {
            width: 80%;
          }
          &:last-child {
            width: calc(100% - 80%);
            text-align: right;
          }
        }
      }
      tbody {
        padding: 0px 20px;
        height: auto;
        tr {
          width: 100%;
          display: flex;
          border-bottom: 1px solid rgba(94, 98, 103, 0.1);
          td {
            text-align: left;
            border: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 15px 0px 20px;
            &:first-child {
              width: 80%;
            }
            &:last-child {
              width: calc(100% - 80%);
              text-align: right;
              flex-direction: row-reverse;
              display: flex;
              justify-content: end;
              align-items: center;
              i,
              p {
                font-weight: bold;
                margin-right: 5px;
              }
              i {
                font-size: 10px;
                margin-top: 5px;
              }
            }
            .activity-txt {
              font-size: 10px;
              font-weight: 500;
              color: #171a1d;
            }
            .date-txt {
              font-size: 13px;
              font-weight: normal;
              color: #5e6267;
            }
          }
        }
      }
    }
  }
}
.points-spends-wrap {
  margin-top: 80px;
  margin-left: 0px;
  margin-right: 0px;
  position: relative;
  .heading {
    font-size: 20px !important;
    font-weight: 800 !important;
    color: #3b1c5a;
    margin-bottom: 30px;
  }
  img {
    position: absolute;
    top: -93px;
    right: 0px;
  }
  .accordion {
    width: 100%;
    box-shadow: unset;
    background-color: unset;
    z-index: 9;
    .accordion-list {
      border-radius: 3px;
      box-shadow: 0 11px 14px -10px #e5eced;
      background-color: #ffffff;
      padding: 20px;
      margin-bottom: 20px;
      .title {
        position: relative;
        font-size: 16px;
        font-weight: 500;
        font-family: "Be Vietnam", sans-serif !important;
        color: #171a1d;
        i {
          position: absolute;
          right: 0px;
          transform: rotate(90deg) !important;
        }
        &.active {
          i {
            transform: rotate(-90deg) !important;
          }
        }
      }
      p {
        font-size: 14px;
        font-weight: normal;
        line-height: 32px;
        color: #5e6267;
      }
    }
  }
}

// .card-row {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
//   margin: 10px;
// }

.points-table-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  background-color: #adb0b3;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 5px;
}

// @media only screen and (min-width: 480px) {
//   .card-row {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-around;
//     margin: 10px;
//   }
// }
