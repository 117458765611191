@import "../../styles/_variables";
@import "../../styles/quillpro/quillpro-variables";

.error404 {
  &__container {
    position: absolute;
    background-image: url("/assets/img/404-boxes-background-cropped.png");
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    width: 50rem;
    height: 30rem;
    box-shadow: 0 0 1rem 1rem #f6f7f9 inset;
    left: 50%;
    top: 30%;
    transform: translateX(-50%);
  }

  &__title {
    position: relative;
    width: 100%;

    &--container {
      position: absolute;
      top: -3rem;
    }

    &--main {
      font-size: 7rem;
    }
    &--sub,
    &--tertiary {
      transform: translateY(-1.5rem);
      a {
        font-weight: bold;

        &:hover {
          color: $qp-color-primary;
          text-decoration: underline;
        }
      }
    }
  }
}
